import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import styles from '../../../../ManageSubscription/styles/SubscriptionContainer.module.scss';
import { TextArea } from '../../../../../components/TextArea/TextArea';
import SubscriptionAppBar from '../../../components/SubscriptionAppBar';
import Chooser from '../../../../../components/Chooser';
import { gqlTypes } from '../../../../../types';
import Button from '../../../../../components/Button';
import { airvetManagement as events } from '../../../../../lib/analytics/events';
import AppPaths from '../../../../../AppPaths';
import Loading from '../../../../../components/Loading';
import { cancelThirdPartyMembershipMutation, userThirdPartiesQuery } from '../../../../../graphql-operations';
import CancelConfirmationModal from './CancelConfirmationModal';
import client from '../../../../../lib/fi-api/client';
import { ThirdPartyId } from '../../../../../types/gql-op-types';

export default function CancelSurvey() {
  const PAGE_NAME = 'Cancel Airvet Membership';
  events.index.pageLoad(PAGE_NAME);

  const history = useHistory();

  const [otherCancelReasonText, setOtherCancelReasonText] = useState('');
  const [selectedCancelReason, setSelectedCancelReason] = useState('');
  const [error, setError] = useState('');
  const [airvetSubscriptionId, setAirvetSubscriptionId] = useState<string | null>(null);
  const [nextBillDate, setNextBillDate] = useState<string | null>(null);

  enum CancellationReason {
    NOT_USING,
    TOO_EXPENSIVE,
    OTHER,
  }

  useEffect(() => {
    if (airvetSubscriptionId) {
      return;
    }
    client
      .query<gqlTypes.userThirdParties>({
        query: userThirdPartiesQuery,
        fetchPolicy: 'cache-first',
      })
      .then((result) => {
        const data = result.data;
        const airvetData = data.currentUser.thirdPartyData?.find((s) => s?.thirdPartyId === ThirdPartyId.AIRVET)?.data;
        airvetData?.subscriptionId && setAirvetSubscriptionId(airvetData.subscriptionId);
        airvetData?.nextBillDate && setNextBillDate(airvetData.nextBillDate);
      })
      .catch((err) => setError(err.message));
  }, [airvetSubscriptionId, setError]);

  const [cancelMutation, cancelMutationState] = useMutation<
    gqlTypes.ECOMMERCE_cancelThirdPartyMembership,
    gqlTypes.ECOMMERCE_cancelThirdPartyMembershipVariables
  >(cancelThirdPartyMembershipMutation, {
    onCompleted: (data) => {
      const success = !!data.cancelThirdPartySubscription?.success;
      if (!success) {
        console.log('set error');
        setError(`Error: ${data.cancelThirdPartySubscription?.reasonForFailure}`);
      } else {
        history.push({
          pathname: AppPaths.Airvet.CancelConfirmation,
          search: window.location.search,
          state: {
            nextBillDate,
          },
        });
      }
    },
  });

  if (cancelMutationState.loading || !airvetSubscriptionId) {
    return <Loading />;
  }

  const handleSubmit = () => {
    cancelMutation({
      variables: {
        input: {
          recurlySubscriptionId: airvetSubscriptionId,
        },
      },
    });
  };

  return (
    <>
      <div className={styles.fullWidthWrapper}>
        <SubscriptionAppBar
          backButtonAnalyticsEvent={() => events.cancellation.surveyBack()}
          closeButtonAnalyticsEvent={() => events.cancellation.surveyClose()}
          noBackButton
        />
        <div className={styles.subscriptionContainer}>
          <div className={styles.sectionHeaderContainer}>
            <h2 className={styles.sectionSubheader}>Why are you cancelling?</h2>
          </div>
          <div className={styles.content}>
            <p>Your feedback can help us improve — and help us keep more dogs safe and healthy.</p>
            <div className={styles.reasonHeader}>Reason for cancellation:</div>
            <div className={styles.content}>
              <Chooser
                onSelect={(selectedReason) => {
                  setSelectedCancelReason(selectedReason);
                }}
                selectedOption={selectedCancelReason}
                groups={[
                  {
                    options: [
                      {
                        content: "I'm not using Airvet",
                        value: CancellationReason[CancellationReason.NOT_USING],
                      },
                      {
                        content: 'Subscription is too expensive',
                        value: CancellationReason[CancellationReason.TOO_EXPENSIVE],
                      },
                      {
                        content: 'Other',
                        value: CancellationReason[CancellationReason.OTHER],
                      },
                    ],
                  },
                ]}
                showRadio
                compact
              />
              {selectedCancelReason === gqlTypes.supplementCancellationReason.OTHER && (
                <div className={styles.textArea}>
                  <TextArea
                    onChange={(value) => {
                      value ? setOtherCancelReasonText(value) : setOtherCancelReasonText('');
                    }}
                    rows={12}
                    placeholderText="Describe your reason here..."
                  />
                </div>
              )}
            </div>
          </div>
        </div>
        <div className={styles.buttonContainer}>
          <CancelConfirmationModal
            onContinue={handleSubmit}
            selectedReason={selectedCancelReason}
            nextBillDate={nextBillDate}
            trigger={
              <Button
                className={styles.button}
                disabled={
                  selectedCancelReason === '' ||
                  (selectedCancelReason === 'OTHER' && otherCancelReasonText.length === 0)
                }
              >
                Submit
              </Button>
            }
          />

          {/* Spacer ensures primary button is aligned with buttons on neighboring pages in in-app cancel flow */}
          <div className={styles.buttonSpacer}>{error && <div className={styles.error}>{error}</div>}</div>
        </div>
      </div>
    </>
  );
}
