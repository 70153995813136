import * as queryString from 'query-string';
import { useSelector } from 'react-redux';
import { AppState } from '../types';
import store from '../lib/reduxStore';
import { tenancyActions } from '../reducers/tenancy';
import { useLocation } from 'react-router-dom';
import { useEffect } from 'react';

export type Tenancy = 'prod' | 'test';

/**
 * @returns Hook for the data tenancy to use for this ecommerce session.
 */
export default function useTenancy(): Tenancy | undefined {
  return useSelector((state: AppState) => state.tenancy) ?? undefined;
}

/**
 * Hook to set the tenancy value from the URL query parameter. Should be run
 * from the top-level app so it's only getting called once.
 */
export function useSetTenancyFromURL() {
  const location = useLocation();
  useEffect(() => {
    parseAndSetTenancyFromURL(location.search);
  }, [location]);
}

/**
 * @returns The current data tenancy value to use for this ecommerce session.
 */
export function getTenancy(): Tenancy | undefined {
  initializeTenancyValue();
  const reduxTenancy = store.getState().tenancy;
  console.debug('Returning tenancy from Redux store.', store.getState().tenancy);
  return reduxTenancy ?? undefined;
}

function initializeTenancyValue(onInitialize?: () => void) {
  // Some call sites (analytics) need the tenancy value before the React app is
  // mounted and the tenancy query param has been written to the Redux store
  // (requires quite a bit of work to fix) so in the meantime check the URL for
  // the tenancy value if it exists, then defaulting to the store once it's been
  // initialized and removed from the URL by useTenancy.
  if ((window as any)._tenancyLoaded) {
    return;
  }
  parseAndSetTenancyFromURL(window.location.search);

  (window as any)._tenancyLoaded = true;
  (window as any).getTenancy = getTenancy;
  onInitialize && onInitialize();
}

function parseAndSetTenancyFromURL(qs: string) {
  const queryParams = queryString.parse(qs);
  // ignore if unset or contains multiple values
  if (queryParams['tenancy'] && typeof queryParams['tenancy'] === 'string') {
    store.dispatch(tenancyActions.setTenancyFromURL(queryParams['tenancy']));
  }
}
