import React from 'react';

import ConfirmModal from '../../../../../components/modals/ConfirmModal';
import * as events from '../../../../../lib/analytics/events';
import styles from '../../../styles/SubscriptionContainer.module.scss';
import { DateTime } from 'luxon';

interface ICancelConfirmationModalProps {
  onContinue: () => void;
  trigger: React.ReactElement;
  selectedReason: string;
  nextBillDate: string | null;
}

export default function CancelConfirmationModal({
  onContinue,
  trigger,
  selectedReason,
  nextBillDate,
}: ICancelConfirmationModalProps) {
  const onAccept = () => {
    events.airvetManagement.cancellation.cancelConfirmationModalContinue(selectedReason);
    onContinue();
  };
  const onDecline = () => {
    events.airvetManagement.cancellation.cancelConfirmationModalCancel();
  };

  return (
    <ConfirmModal
      trigger={trigger}
      body={
        <>
          <div className={styles.header}>
            <h3>Are you sure?</h3>
          </div>
          <p>
            You will no longer have access to your Airvet account after{' '}
            {nextBillDate ? DateTime.fromISO(nextBillDate).toFormat('LLLL d, yyyy') : 'your next bill date'}.
          </p>
        </>
      }
      acceptText={'Yes, cancel Airvet membership'}
      declineText={'Nevermind, go back'}
      onAccept={onAccept}
      onDecline={onDecline}
    />
  );
}
