import React from 'react';
import { useHistory } from 'react-router-dom';
import { ReactComponent as BackIcon } from '../../../../assets/images/icons/back.svg';
import { ReactComponent as CloseIcon } from '../../../../assets/images/icons/close.svg';
import { ReactComponent as AddIcon } from '../../../../assets/images/icons/add.svg';
import styles from './SubscriptionAppBar.module.scss';
import classNames from 'classnames';
import { EmptyShoppingBagIcon, ShoppingBagIcon } from '../../../../components/AppBar/ShoppingBag';

/** Used on confirmation screens where no back button is needed, but we still want consistent whitespace at top of screen. */
export function SubscriptionAppBarSpacer() {
  return <div className={styles.container} />;
}

interface SubscriptionAppBarProps {
  title?: string;
  backButtonExitsWebview?: boolean;
  forceBackButtonBehavior?: () => void;
  backButtonAnalyticsEvent?: () => void;
  closeButtonAnalyticsEvent: () => void;
  noCloseButton?: boolean;
  noBackButton?: boolean;
  addButtonAction?: () => void; // No close button must be true to use this
  darkBackground?: boolean;
  shoppingBagValue?: number;
  shoppingBagOnClick?: () => void;
}

const CLEAR_STATE_FOR_PATHNAMES = ['/supplements'];

export default function SubscriptionAppBar({
  title,
  backButtonExitsWebview,
  backButtonAnalyticsEvent,
  forceBackButtonBehavior,
  closeButtonAnalyticsEvent,
  noCloseButton,
  noBackButton,
  addButtonAction,
  darkBackground,
  shoppingBagValue,
  shoppingBagOnClick,
}: SubscriptionAppBarProps) {
  const history = useHistory();
  const disableBackButton = history.length === 1 && history.location.pathname.includes('/payment/update');
  return (
    <div className={classNames(styles.container, { [styles.darkBackground]: darkBackground })}>
      <div className={styles.subAppBarContent}>
        {!noBackButton && (
          <div
            className={classNames(styles.button, { [styles.hiddenBackButton]: disableBackButton })}
            onClick={() => {
              backButtonAnalyticsEvent && backButtonAnalyticsEvent();
              if (backButtonExitsWebview) {
                window.open('fi://closeappview');
              } else if (forceBackButtonBehavior) {
                forceBackButtonBehavior();
              } else {
                // Clear state for supplements pages so that toast notifications on splash page don't
                // surface when they're not meant to.
                CLEAR_STATE_FOR_PATHNAMES.forEach((pathname) => history.replace(pathname, {}));
                history.goBack();
              }
            }}
          >
            <BackIcon />
          </div>
        )}

        <div className={styles.title}>{title ?? ''}</div>

        {!noCloseButton && (
          <div
            className={styles.button}
            onClick={() => {
              closeButtonAnalyticsEvent();
              window.open('fi://closeappview');
            }}
          >
            <CloseIcon />
          </div>
        )}

        {noCloseButton && addButtonAction && (
          <div className={styles.button} onClick={addButtonAction}>
            <AddIcon />
          </div>
        )}

        {noCloseButton && shoppingBagValue !== undefined && (
          <div
            className={classNames(styles.button, { [styles.hiddenShoppingBag]: shoppingBagValue <= 0 })}
            onClick={shoppingBagOnClick}
          >
            {shoppingBagValue <= 0 ? <EmptyShoppingBagIcon /> : <ShoppingBagIcon count={shoppingBagValue} />}
          </div>
        )}
      </div>
    </div>
  );
}
