import React from 'react';
import { RecurlyProvider } from '@recurly/react-recurly';
import useTenancy from '../../hooks/useTenancy';

const RECURLY_PUBLIC_KEY = process.env.REACT_APP_RECURLY_PUBLIC_KEY || '';
const TEST_TENANCY_RECURLY_PUBLIC_KEY = process.env.REACT_APP_TEST_TENANCY_RECURLY_PUBLIC_KEY || '';

interface FiRecurlyProviderProps {
  children: JSX.Element;
  /**
   * If true, the fact that recurly.js is not available is not 100% necessary for the user to continue
   * For example: recurly.js is necessary for the Apple Pay and PayPal button, but the user can continue with a
   * credit card number.
   */
  nonEssential?: boolean;
}

/**
 * Recurly provider with the Fi public key. Best use of this is to wrap only the elements that need to use Recurly.
 */
export default function FiRecurlyProvider({ children, nonEssential }: FiRecurlyProviderProps) {
  const tenancy = useTenancy();

  // Recurly provider will throw 'Please load Recurly.js (https://js.recurly.com/v4/recurly.js) on this page prior to
  // loading your React application.' if window.recurly is not defined. The most common reason we've seen is because
  // it's a Google crawler and Recurly's domain has a robots.txt
  if (window.recurly) {
    if (tenancy === 'test') {
      console.debug(`Using test tenancy Recurly public key: ${TEST_TENANCY_RECURLY_PUBLIC_KEY}`);
      return <RecurlyProvider publicKey={TEST_TENANCY_RECURLY_PUBLIC_KEY}>{children}</RecurlyProvider>;
    }
    console.debug(`Using prod tenancy Recurly public key: ${RECURLY_PUBLIC_KEY}`);
    return <RecurlyProvider publicKey={RECURLY_PUBLIC_KEY}>{children}</RecurlyProvider>;
  }

  if (nonEssential) {
    return null;
  }

  return (
    <h3>
      We were unable to load the billing form. Please reload the page or contact support@tryfi.com if the problem
      persists.
    </h3>
  );
}
