import React, { useState } from 'react';

import Popup from 'reactjs-popup';
import styles from './UpdateFrequencyModal.module.scss';
import { ReactComponent as CloseIcon } from '../../../../../assets/images/icons/close.svg';
import { supplementsManagement as events } from '../../../../../lib/analytics/events';
import { ReactComponent as ArrowAngleIcon } from '../../../../../assets/images/icons/arrow_angle.svg';
import { WeightFrequencySelector } from '../DeliveryFrequencyModal/DeliveryFrequencyModal';
import { ReactComponent as AddIcon } from '../../../../../assets/images/icons/add.svg';
import { ReactComponent as MinusIcon } from '../../../../../assets/images/icons/minus.svg';
import { ReactComponent as BackIcon } from '../../../../../assets/images/icons/back.svg';
import classNames from 'classnames';

interface IUpdateFrequencyModalProps {
  onContinue: (frequency: number) => void;
  trigger: React.ReactElement;
  initialFrequency: number;
}

function UpdateFrequencySelector({
  onContinue,
  close,
  setWhatSizeIsRightOpen,
  initialFrequency,
}: {
  onContinue: (frequency: number) => void;
  close: () => void;
  setWhatSizeIsRightOpen: (open: boolean) => void;
  initialFrequency: number;
}) {
  const [autoshipFrequency, setAutoshipFrequency] = useState<number>(initialFrequency);

  return (
    <div className={styles.container}>
      <div className={classNames(styles.header, styles.headerWithCloseIcon)}>
        <div className={styles.headerTitle}>Shipment frequency</div>
        <CloseIcon
          onClick={() => {
            events.modalCloseEvent({}, 'Update Cadence');
            close();
          }}
        />
      </div>
      <div className={styles.frequencyContainer}>
        <button
          className={classNames(styles.frequencyMinusButton, {
            [styles.frequencyMinusButtonDisabled]: autoshipFrequency === 1,
          })}
          onClick={() => setAutoshipFrequency(Math.max(autoshipFrequency - 1, 1))}
        >
          <MinusIcon />
        </button>
        <div className={styles.frequencyValue}>
          {autoshipFrequency}{' '}
          <span className={styles.frequencyValueUnit}>{autoshipFrequency === 1 ? 'week' : 'weeks'}</span>
        </div>
        <button
          className={classNames(styles.frequencyPlusButton, {
            [styles.frequencyPlusButtonDisabled]: autoshipFrequency === 12,
          })}
          onClick={() => setAutoshipFrequency(Math.min(autoshipFrequency + 1, 12))}
        >
          <AddIcon />
        </button>
      </div>
      <div className={styles.whatIsRightContainer} onClick={() => setWhatSizeIsRightOpen(true)}>
        <div className={styles.whatIsRightTitle}>What is right for your dog?</div>
        <div className={styles.whatIsRightIcon}>
          <ArrowAngleIcon />
        </div>
      </div>
      <button
        className={styles.saveButton}
        onClick={() => {
          onContinue(autoshipFrequency);
          close();
        }}
      >
        Save
      </button>
    </div>
  );
}

function WeightFrequencyContainer({
  onContinue,
  setWhatSizeIsRightOpen,
}: {
  onContinue: (frequency: number) => void;
  setWhatSizeIsRightOpen: (open: boolean) => void;
}) {
  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <BackIcon
          onClick={() => {
            setWhatSizeIsRightOpen(false);
          }}
        />
        <div className={styles.headerTitle}>What is your dog’s weight?</div>
      </div>
      <div className={styles.weightFrequencySelectorContainer}>
        <WeightFrequencySelector onContinue={onContinue} close={() => setWhatSizeIsRightOpen(false)} />
      </div>
    </div>
  );
}

export default function UpdateFrequencyModal({ onContinue, trigger, initialFrequency }: IUpdateFrequencyModalProps) {
  const [whatSizeIsRightOpen, setWhatSizeIsRightOpen] = useState(false);

  return (
    <Popup className="s3-popup" trigger={trigger} modal closeOnDocumentClick={false}>
      {(close: () => void) =>
        whatSizeIsRightOpen ? (
          <WeightFrequencyContainer onContinue={onContinue} setWhatSizeIsRightOpen={setWhatSizeIsRightOpen} />
        ) : (
          <UpdateFrequencySelector
            onContinue={onContinue}
            close={close}
            setWhatSizeIsRightOpen={setWhatSizeIsRightOpen}
            initialFrequency={initialFrequency}
          />
        )
      }
    </Popup>
  );
}
