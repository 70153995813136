import { createSlice, PayloadAction, createListenerMiddleware, Dispatch } from '@reduxjs/toolkit';
import * as types from '../types';
import { Tenancy } from '../hooks/useTenancy';
import store from '../lib/reduxStore';

const name = 'tenancy' as const;
const { actions, reducer } = createSlice({
  name,
  initialState: null as Tenancy | null,
  reducers: {
    // don't use this directly outside of this file, use
    // tenancyActions.setTenancyFromURL instead.
    set(_state: Tenancy | null, { payload }: PayloadAction<Tenancy | null>) {
      return payload;
    },
  },
});

export const tenancyActions = {
  setTenancyFromURL: (tenancy: string) => (dispatch: Dispatch) => {
    const session = store.getState().session;
    if (session) {
      console.debug('Ignoring tenancy from URL because user session is already set: ', session.tenancy);
      return;
    }
    if (!['prod', 'test'].includes(tenancy)) {
      console.debug('Invalid tenancy value in URL, ignoring.');
      return;
    }
    dispatch(actions.set(tenancy as Tenancy));
  },
};

export const tenancyReducer = reducer;
export const tenancyInitialState = { [name]: null as Tenancy | null };

const listenerMiddleware = createListenerMiddleware<types.AppState>();
// if the user logs in, we need to update the tenancy to match the user's session
listenerMiddleware.startListening({
  predicate: (_action, currentState, previousState) => {
    return currentState.session !== previousState.session;
  },
  effect: (_action, listenerApi) => {
    const session = listenerApi.getState().session;
    console.debug('Setting tenancy based on new user session', session?.tenancy);
    listenerApi.dispatch(actions.set(session?.tenancy ?? null));
  },
});
