import React from 'react';
import Popup from 'reactjs-popup';
import styles from './InAppCheckoutModal.module.scss';
import { useQuery } from '@apollo/client';

import { ReactComponent as CloseIcon } from '../../../../../assets/images/icons/close.svg';
import { PurchaseType, SupplementCartItem, SUPPLEMENT_BAG_IMAGES, getCartSKUs } from '../UpsellV2/UpsellV2';
import { ReactComponent as TrashIcon } from '../../../../../assets/images/icons/trash.svg';
import { getMultipleSupplementSubscriptionsPricingQuery } from '../../../../../graphql-operations/Subscription.graphql';
import * as types from '../../../../../types';
import { formattedCentsToDollars, getLoadingOrErrorElement } from '../../../../../lib/util';

interface IInAppCheckoutModalProps {
  onPurchase: () => void;
  onClose: () => void;
  trigger: React.ReactElement;
  open: boolean;
  cartItems: SupplementCartItem[];
  onRemoveItem: (index: number, close: () => void) => void;
}

export default function InAppCheckoutModal({
  onPurchase,
  onClose,
  trigger,
  open,
  cartItems,
  onRemoveItem,
}: IInAppCheckoutModalProps) {
  const { data, loading, error } = useQuery<
    types.gqlTypes.ECOMMERCE_getMultipleSupplementSubscriptionsPricing,
    types.gqlTypes.ECOMMERCE_getMultipleSupplementSubscriptionsPricingVariables
  >(getMultipleSupplementSubscriptionsPricingQuery, {
    variables: { input: { skusToAdd: getCartSKUs(cartItems) } },
  });

  const getItemPrice = (purchaseType: PurchaseType): number => {
    return purchaseType === PurchaseType.Autoship ? 25 : 30;
  };
  const cartContainsFreeTrial = cartItems.some((item) => item.includeFreeTrial);
  const tax = formattedCentsToDollars(data?.getMultipleSupplementSubscriptionsPricing?.taxInCents || 0);
  const totalPrice = formattedCentsToDollars(data?.getMultipleSupplementSubscriptionsPricing?.totalInCents || 0);

  const loadingOrErrorElement = getLoadingOrErrorElement(loading, error);
  if (loadingOrErrorElement) {
    return (
      <Popup className="s3-popup" open={open} onClose={onClose} trigger={trigger} modal>
        {() => loadingOrErrorElement}
      </Popup>
    );
  }

  return (
    <Popup className="s3-popup" open={open} onClose={onClose} trigger={trigger} modal>
      {(close: () => void) => (
        <div className={styles.container}>
          <div className={styles.header}>
            <div className={styles.title}>Check out</div>
            <div className={styles.closeIcon}>
              <CloseIcon onClick={() => close()} />
            </div>
          </div>
          <div className={styles.body}>
            <div className={styles.cartItemsContainer}>
              {cartItems.map((item, index) => (
                <div className={styles.cartItem} key={index}>
                  <div className={styles.cartItemDetails}>
                    <div className={styles.cartItemImage}>
                      <img src={SUPPLEMENT_BAG_IMAGES[item.skuType].url} alt={item.skuType} />
                    </div>
                    <div className={styles.cartItemText}>
                      <div className={styles.cartItemTitle}>{item.skuType}</div>
                      {item.purchaseType === PurchaseType.Autoship && (
                        <div className={styles.cartItemDescription}>
                          Auto ships every {item.frequency === 1 ? 'week' : `${item.frequency} weeks`}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className={styles.cartItemPrice}>
                    <div className={styles.cartItemPriceValue}>${getItemPrice(item.purchaseType)}</div>
                    <div className={styles.cartItemRemoveButton} onClick={() => onRemoveItem(index, close)}>
                      <TrashIcon />
                    </div>
                  </div>
                </div>
              ))}
            </div>
            {cartContainsFreeTrial && (
              <div className={styles.promotionContainer}>
                <div className={styles.promotionTitle}>First bag special promotion</div>
                <div className={styles.promotionValue}>-$10</div>
              </div>
            )}
            <div className={styles.taxContainer}>
              <div className={styles.taxTitle}>Tax:</div>
              <div className={styles.taxValue}>{tax}</div>
            </div>
            <div className={styles.totalDueContainer}>
              <div className={styles.totalDueTitle}>Total due today:</div>
              <div className={styles.totalDueValue}>{totalPrice}</div>
            </div>
            <button className={styles.confirmOrderButton} onClick={onPurchase}>
              Confirm order
            </button>
            <button className={styles.continueShoppingButton} onClick={() => close()}>
              Continue shopping
            </button>
            <div className={styles.disclaimer}>
              By taping Confirm Order you accept the{' '}
              <a href="https://tryfi.com/tos" target="_blank" rel="noopener noreferrer">
                Terms & Conditions.
              </a>
            </div>
          </div>
        </div>
      )}
    </Popup>
  );
}
