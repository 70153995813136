import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import styles from '../styles/ManageActions.module.scss';
import { supplementsManagement as events } from '../../../../lib/analytics/events';
import { ReactComponent as SkipIcon } from '../../../../assets/images/icons/supplements_management/icon_skip.svg';
import { ReactComponent as ArrowIcon } from '../../../../assets/images/icons/arrow.svg';
import { ReactComponent as CalendarIcon } from '../../../../assets/images/icons/supplements_management/icon_calendar.svg';
import { ReactComponent as TruckIcon } from '../../../../assets/images/icons/supplements_management/icon_truck.svg';
import { getLoadingOrErrorElement } from '../../../../lib/util';
import { useMutation } from '@apollo/client';
import AppPaths from '../../../../AppPaths';
import { supplementsSubscriptionsQuery, setSupplementNextShipDateMutation } from '../../../../graphql-operations';
import * as types from '../../../../types';
import { BillingSubscription } from '../SplashPage';
import classNames from 'classnames';
import { DateTime } from 'luxon';
import ErrorMessage, { useError } from '../../../../components/ErrorMessage';
import Loading from '../../../../components/Loading';
import { logInternalError } from '../../../../lib/errors';
import SelectShipDateModal from './SelectShipDateModal';
import SendNowModal from './SendNowModal';
import SkipShipmentModal from './SkipShipmentModal';

interface ManageShipmentActionsState {
  subscription: BillingSubscription;
}

export default function ManageShipmentActions() {
  const location = useLocation<ManageShipmentActionsState>();
  const { subscription } = location.state;
  const history = useHistory();
  const { error, errorID, setError } = useError();
  const TIMEZONE = 'America/New_York';

  const [setShipDateMutation, setShipDateMutationState] = useMutation<
    types.gqlTypes.ECOMMERCE_setSupplementNextShipDate,
    types.gqlTypes.ECOMMERCE_setSupplementNextShipDateVariables
  >(setSupplementNextShipDateMutation, {
    refetchQueries: [{ query: supplementsSubscriptionsQuery }],
    onCompleted: (data) => {
      const success = !!data.setSupplementNextShipDate?.success;
      if (success) {
        history.push({
          pathname: AppPaths.Supplements.Root,
          state: { toast: { success: true, change: 'updated' }, refetch: false },
          search: window.location.search,
        });
        events.setShipDate({ subscriptionId: subscription.id });
      }
      setError(`Failed to update next ship date: ${data.setSupplementNextShipDate?.reasonForFailure}`);
    },
    onError(err) {
      setError(err.message);
      logInternalError(err);
    },
  });

  const setShipDateLoading = getLoadingOrErrorElement(setShipDateMutationState.loading, null);

  const previewResumingShipmentDate = subscription.supplementShipmentDates?.previewResumingShipmentDate;
  const previewSkippingShipmentDate = subscription.supplementShipmentDates?.previewSkippingShipmentDate;
  const { weeksBetweenShipments } = subscription.subscriptionOption;
  if (!previewResumingShipmentDate) {
    throw new Error('Missing skipped shipment date. Please contact support@tryfi.com.');
  }
  if (!previewSkippingShipmentDate) {
    throw new Error('Missing unskipped shipment date. Please contact support@tryfi.com.');
  }
  if (!weeksBetweenShipments) {
    throw new Error('Missing shipping frequency. Please contact support@tryfi.com.');
  }

  const nextShipmentText = () => {
    return `Currently ${DateTime.fromISO(subscription.currentTermEndsAt).toFormat('cccc, LLL d')}`;
  };

  const handleSetShipDateClick = (date: any) => {
    const dateWithTimeZone = DateTime.fromISO(date).setZone(TIMEZONE).set({ hour: 6, minute: 0 }).toJSDate();
    setShipDateMutation({
      variables: {
        input: {
          recurlySubscriptionId: subscription.id,
          nextShipDate: dateWithTimeZone,
        },
      },
    });
  };

  return (
    <div className={styles.manageActionsContainer}>
      <>
        {setShipDateLoading ? (
          <Loading className={styles.loading} />
        ) : (
          <div className={styles.options}>
            <SelectShipDateModal
              trigger={
                <div className={classNames(styles.option, styles.hasArrow)}>
                  <div className={styles.block}>
                    <div className={styles.icon}>
                      <CalendarIcon />
                    </div>
                    <div className={styles.text}>
                      <div className={styles.title}>Update shipment date</div>
                      {nextShipmentText && <div className={styles.subtitle}>{nextShipmentText()}</div>}
                    </div>
                  </div>
                  <div className={styles.actionArrow}>
                    <ArrowIcon />
                  </div>
                </div>
              }
              onContinue={(date: string) => handleSetShipDateClick(date)}
              subscription={subscription}
            />
            <div className={styles.row}>
              <SkipShipmentModal
                trigger={
                  <div className={classNames(styles.option, styles.shorterOption)}>
                    <div className={styles.block}>
                      <div className={styles.icon}>
                        <SkipIcon />
                      </div>
                      <div className={styles.text}>
                        <div className={styles.title}>Skip shipment</div>
                      </div>
                    </div>
                  </div>
                }
                handleSelectDate={(date: string) => handleSetShipDateClick(date)}
                subscription={subscription}
              />
              <SendNowModal
                trigger={
                  <div className={classNames(styles.option, styles.shorterOption)}>
                    <div className={styles.block}>
                      <div className={styles.icon}>
                        <TruckIcon />
                      </div>
                      <div className={styles.text}>
                        <div className={styles.title}>Send now</div>
                      </div>
                    </div>
                  </div>
                }
                onSendNow={(date: string) => {
                  events.defaultSendNowAccepted({ subscriptionId: subscription.id });
                  handleSetShipDateClick(date);
                }}
                onChooseDifferentDate={(date: string) => handleSetShipDateClick(date)}
                subscription={subscription}
              />
            </div>
          </div>
        )}
        {error && <ErrorMessage errors={[error]} errorID={errorID} />}
      </>
    </div>
  );
}
