import React from 'react';
import { Switch } from 'react-router';
import AppPaths from '../../AppPaths';
import SentryRoute from '../../SentryRoute';
import RenewAndSaveSelection from './RenewAndSaveSelection';
import { PurchaseSubscriptionContextProvider } from '../PurchaseSubscription/context/PurchaseSubscriptionContext';
import RenewAndSaveComplete from './RenewAndSaveComplete';

export default function RenewAndSave() {
  return (
    <PurchaseSubscriptionContextProvider>
      <Switch>
        <SentryRoute exact path={AppPaths.RenewAndSave.PlanSelection()} component={RenewAndSaveSelection} />
        <SentryRoute exact path={AppPaths.RenewAndSave.Complete()} component={RenewAndSaveComplete} />
      </Switch>
    </PurchaseSubscriptionContextProvider>
  );
}
