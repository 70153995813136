import React, { useEffect } from 'react';
import styles from './RenewAndSaveSelection.module.scss';
import Button from '../../components/Button';
import { centsToDollarsNumber, getLoadingOrErrorElement } from '../../lib/util';
import { Image } from '../../components/Image';
import CollarHero from '../../assets/images/collar_hero_blue_3.png';
import Check from '../../assets/images/icons/check_green.svg';
import RenewAndSaveConfirmationModal from './RenewAndSaveConfirmationModal';
import { usePurchaseSubscriptionContext } from '../PurchaseSubscription/context/PurchaseSubscriptionContext';
import { DateTime } from 'luxon';
import ErrorMessage, { useError } from '../../components/ErrorMessage';
import { purchaseSubscriptionDeviceDetails } from '../../types/gql-op-types';
import { renewAndSave } from '../../lib/analytics/events';
import { gqlTypes } from '../../types';
import { useMutation, useQuery } from '@apollo/client';
import { previewRenewAndSaveQuery, purchaseRenewAndSaveMutation } from '../../graphql-operations/RenewAndSave.graphql';
import { useHistory } from 'react-router-dom';
import AppPaths from '../../AppPaths';

export default function RenewAndSaveSelection() {
  useEffect(() => renewAndSave.viewed(), []);

  const { error, errorID, setError } = useError();
  const { device } = usePurchaseSubscriptionContext();
  const { currentCostInCents, currentTermMonths, earlyRenewalSuggestedTermEnd, currentTermEndsAt } =
    getRenewAndSaveDetailsFromDevice(device);
  const previewRenewAndSaveQueryResult = usePreviewRenewAndSaveQuery(device.moduleId, setError);

  const { purchaseRenewAndSave, loadingPurchaseRenewAndSave } = usePurchaseRenewAndSaveMutation(
    device.moduleId,
    currentTermMonths,
    setError,
  );

  // Hide screen behind error if unable to show the offer
  const previewRenewLoadingOrErrorElement = getLoadingOrErrorElement(
    previewRenewAndSaveQueryResult.loading,
    previewRenewAndSaveQueryResult.error,
  );
  if (previewRenewLoadingOrErrorElement) {
    return previewRenewLoadingOrErrorElement;
  }

  // Only show loading state if we are waiting for the purchase to complete, errors are handled in the modal.
  const purchaseRenewLoadingOrErrorElement = getLoadingOrErrorElement(loadingPurchaseRenewAndSave.loading, null);
  if (purchaseRenewLoadingOrErrorElement) {
    return purchaseRenewLoadingOrErrorElement;
  }

  const earlyRenewalChargeAmountCents =
    previewRenewAndSaveQueryResult.data?.previewRenewAndSave?.subtotalInCents ?? currentCostInCents;
  const earlyRenewalSavingsInCents = currentCostInCents - earlyRenewalChargeAmountCents;

  const triggerRenewAndSaveModal = (
    <Button
      className={styles.simpleButton}
      disabled={loadingPurchaseRenewAndSave.loading && !error}
      onClick={() => renewAndSave.earlyRenewPlan()}
    >
      Claim Offer
    </Button>
  );

  return (
    <>
      <div className={styles.simpleFullWidthWrapper}>
        <div className={styles.simpleContainer}>
          <div className={styles.specialOfferChip}>
            <p>LIMITED TIME OFFER</p>
          </div>
          <div className={styles.simpleHeaderContainer}>
            <h1 className={styles.simpleHeader}>Renew & save ${centsToDollarsNumber(earlyRenewalSavingsInCents)}</h1>
            <p className={styles.simpleSubheader}>
              Pay ${centsToDollarsNumber(earlyRenewalChargeAmountCents)} to renew your membership through <br />
              {earlyRenewalSuggestedTermEnd}.
            </p>
          </div>
          <div className={styles.offerContainer}>
            <div className={styles.offerDetailsContainer}>
              <p className={styles.upsellSubscriptionTermText}>{currentTermMonths} month renewal</p>
              <div className={styles.pricesContainer}>
                <p className={styles.upsellSubscriptionPriceText}>
                  ${centsToDollarsNumber(earlyRenewalChargeAmountCents)}
                </p>
                <p className={styles.currentSubscriptionPriceText}>${centsToDollarsNumber(currentCostInCents)}</p>
              </div>
              <Image className={styles.checkmark} image={{ type: 'image', url: Check }} />
            </div>
          </div>
          <p className={styles.savingsText}>
            Current plan renews for ${centsToDollarsNumber(currentCostInCents)} on {currentTermEndsAt}
          </p>
          <div className={styles.collarContainer}>
            <Image className={styles.collarImage} image={{ type: 'image', url: CollarHero }} />
          </div>
          <RenewAndSaveConfirmationModal
            earlyRenewalSuggestedTermEnd={earlyRenewalSuggestedTermEnd}
            earlyRenewalChargeAmountCents={earlyRenewalChargeAmountCents}
            termLengthMonths={currentTermMonths}
            trigger={triggerRenewAndSaveModal}
            onAccept={() => {
              renewAndSave.confirmationModalContinue();
              purchaseRenewAndSave();
            }}
            onDecline={() => {
              renewAndSave.confirmationModalGoBack();
            }}
          />
          {error && <ErrorMessage errors={[error]} errorID={errorID} />}
        </div>
      </div>
    </>
  );
}

function getRenewAndSaveDetailsFromDevice(device: purchaseSubscriptionDeviceDetails) {
  const subscription = device.subscription!; // Dangerous! However, it should be there.

  const currentCostInCents = subscription.subscriptionOption.priceInCents;
  const currentTermMonths = subscription.subscriptionOption.renewalMonths;
  const earlyRenewalSuggestedTermEnd = DateTime.fromJSDate(new Date(subscription.currentTermEndsAt))
    .plus({ months: currentTermMonths })
    .toLocaleString({ year: 'numeric', month: 'long', day: 'numeric' });

  const currentTermEndsAt = DateTime.fromJSDate(new Date(subscription.currentTermEndsAt)).toLocaleString({
    month: 'long',
    day: 'numeric',
    year: 'numeric',
  });

  return {
    currentCostInCents,
    currentTermMonths,
    currentTermEndsAt,
    earlyRenewalSuggestedTermEnd,
  };
}

function usePreviewRenewAndSaveQuery(moduleId: string, setError: (error: string) => void) {
  return useQuery<gqlTypes.ECOMMERCE_previewRenewAndSave, gqlTypes.ECOMMERCE_previewRenewAndSaveVariables>(
    previewRenewAndSaveQuery,
    {
      variables: {
        input: {
          moduleId: moduleId,
        },
      },
      onError(err) {
        setError(err.message);
      },
    },
  );
}

function usePurchaseRenewAndSaveMutation(
  moduleId: string,
  currentTermMonths: number,
  setError: (error: string) => void,
) {
  const history = useHistory();

  const [purchaseRenewAndSave, loadingPurchaseRenewAndSave] = useMutation<
    gqlTypes.ECOMMERCE_purchaseRenewAndSave,
    gqlTypes.ECOMMERCE_purchaseRenewAndSaveVariables
  >(purchaseRenewAndSaveMutation, {
    variables: {
      input: {
        moduleId: moduleId,
      },
    },
    onCompleted() {
      renewAndSave.successfullyRenewedAndSaved();
      history.push({
        pathname: AppPaths.RenewAndSave.Complete(moduleId),
        search: window.location.search,
        state: { termMonths: currentTermMonths },
      });
    },
    onError(err) {
      setError(err.message);
    },
  });

  return { purchaseRenewAndSave, loadingPurchaseRenewAndSave };
}
