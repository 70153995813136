import analytics from '.';
import { SubscriptionOption } from '../../components/SubscriptionOptionDetails';
import {
  Address,
  BillingInfo,
  Cart,
  CartItem,
  CartPricing,
  ColorOption,
  IProduct,
  ISubscriptionProduct,
  IVariant,
  PaymentContext,
  ProductCategory,
  SizeOption,
  SupplementCheckoutFlow,
} from '../../types';
import { cartItemToAxonItems, maybeAddAxonSetupFeeItem, productEventPropsToAxonItem } from '../../types/Axon';
import * as gqlTypes from '../../types/gql-op-types';
import { SubscriptionCategory } from '../../views/ManageSubscription/Collar/util/types';
import { CancellationDeterrents } from '../../views/ManageSubscription/Supplements/util/types';
import { BandVariantGroup } from '../../views/ProductDetails/components/BandVariantOptions/BandVariantOptions';
import { isUpgradeCartItem, skusForCartItem } from '../cart';
import { getProductsAndVariantsBySku, variantDescriptionForAnalytics } from '../product';
import store from '../reduxStore';
import { centsToDollars, centsToDollarsNumber, expectUnreachable } from '../util';
import { typedWindow } from '../Window';
import { getCartInformation } from './analyticsUtils';
import * as postie from './postie';

export interface EventDetails {
  eventName: string;
  eventProps?: any;
}

interface PageLoad {
  kind: 'PageLoad';
}

interface VariantChanged {
  kind: 'VariantChanged';
  previousVariant: IVariant;
}

interface ProductChanged {
  kind: 'ProductChanged';
}

export type ProductViewReason = PageLoad | VariantChanged | ProductChanged;

type EComEventName =
  | 'Product Viewed'
  | 'Product Added'
  | 'Cart Viewed'
  | 'Checkout Started'
  | 'Checkout Step Completed'
  | 'Payment Info Entered'
  | 'Product Removed'
  | 'Order Completed'
  | 'Subscription Selected';

export const ECOM_EVENT_PRODUCT_VIEWED: EComEventName = 'Product Viewed';
export const ECOM_EVENT_PRODUCT_ADDED: EComEventName = 'Product Added';
export const ECOM_EVENT_CART_VIEWED: EComEventName = 'Cart Viewed';
export const ECOM_EVENT_CHECKOUT_STARTED: EComEventName = 'Checkout Started';
export const ECOM_EVENT_CHECKOUT_STEP_COMPLETED: EComEventName = 'Checkout Step Completed';
export const ECOM_EVENT_PAYMENT_INFO_ENTERED: EComEventName = 'Payment Info Entered';
export const ECOM_EVENT_PRODUCT_REMOVED: EComEventName = 'Product Removed';
export const ECOM_EVENT_ORDER_COMPLETED: EComEventName = 'Order Completed';
export const ECOM_EVENT_SUBSCRIPTION_SELECTED: EComEventName = 'Subscription Selected';

export interface SegmentProduct {
  id: string;
  product_id: string;
  sku: string;
  name: string;
  variant?: string;
  price: number;
  quantity: number;
  isUpgrade?: boolean;
  subscriptionPrice?: number;
}

export interface SegmentPurchaseEventProperties {
  orderId: string;
  products: SegmentProduct[];
  total: number;
  shipping?: number;
  tax?: number;
  code?: string;
}

function skuToSegmentProduct(sku: string): SegmentProduct | undefined {
  const productsAndVariantsBySku = getProductsAndVariantsBySku(store.getState().config.products);
  const productAndVariant = productsAndVariantsBySku.get(sku);
  if (!productAndVariant) {
    return undefined;
  }

  const { product, variant } = productAndVariant;

  return {
    id: sku,
    product_id: sku,
    sku,
    name: product.name,
    variant: variant ? variantDescriptionForAnalytics(variant) : undefined,
    price: centsToDollarsNumber(product.priceInCents),
    quantity: 1,
  };
}

// One cart item may map to multiple products for tracking purposes-
// specifically, bundles might include addon products (e.g. a collar kit with a second band)
export function cartItemToSegmentProducts(cartItem: CartItem) {
  const isUpgrade = isUpgradeCartItem(cartItem);
  const skus = skusForCartItem(cartItem);

  return skus.flatMap((sku) => {
    const segmentProduct = skuToSegmentProduct(sku);
    if (!segmentProduct) {
      return [];
    }

    return [{ ...segmentProduct, isUpgrade }];
  });
}

function cartItemsToSegmentProducts(cartItems: { [id: string]: CartItem }) {
  let segmentProducts: SegmentProduct[] = [];

  for (const cartItem of Object.values(cartItems)) {
    const cartItemProducts = cartItemToSegmentProducts(cartItem);
    segmentProducts = segmentProducts.concat(cartItemProducts);
  }

  return segmentProducts;
}

export function subscriptionSelected(product: ISubscriptionProduct) {
  const fiEventProps = {
    plan: product.id,
    sku: product.sku,
    months: product.renewalMonths,
    price: product.priceInCents,
  };
  analytics.track(ECOM_EVENT_SUBSCRIPTION_SELECTED, fiEventProps);
}

export interface ProductEventProps {
  priceInCents: number;
  productName: string;
  quantity?: number;
  sku: string;
  isUpgrade?: boolean;
}

function ecommerceProductEventProperties({
  priceInCents,
  productName,
  quantity = 1,
  sku,
  isUpgrade,
}: ProductEventProps): SegmentProduct {
  return {
    id: sku,
    name: productName,
    price: centsToDollarsNumber(priceInCents),
    product_id: sku,
    quantity,
    sku,
    isUpgrade,
  };
}

export function chargingBaseViewed(props: ProductEventProps) {
  // Fi event
  analytics.track('Base page', {});

  // Segment standardized ecommerce event
  analytics.track(ECOM_EVENT_PRODUCT_VIEWED, ecommerceProductEventProperties(props));

  if (typedWindow.axon) {
    typedWindow.axon('track', 'view_item', {
      currency: 'USD',
      items: [productEventPropsToAxonItem(props)],
    });
  }
}

export function chargingBaseAddedToBag(props: ProductEventProps) {
  // Fi event
  analytics.track('Base page > Add to bag', {});

  // Segment standardized ecommerce event
  analytics.track(ECOM_EVENT_PRODUCT_ADDED, ecommerceProductEventProperties(props));

  if (typedWindow.axon) {
    typedWindow.axon('track', 'add_to_cart', {
      currency: 'USD',
      items: [productEventPropsToAxonItem(props)],
    });
  }
}

export function nanoViewed(props: ProductEventProps) {
  // Fi event
  analytics.track('Nano page', {});

  // Segment standardized ecommerce event
  analytics.track(ECOM_EVENT_PRODUCT_VIEWED, ecommerceProductEventProperties(props));

  if (typedWindow.axon) {
    typedWindow.axon('track', 'view_item', {
      currency: 'USD',
      items: [productEventPropsToAxonItem(props)],
    });
  }
}

export function nanoAddedToBag(props: ProductEventProps) {
  // Fi event
  analytics.track('Nano page > Add to bag', {});

  // Segment standardized ecommerce event
  analytics.track(ECOM_EVENT_PRODUCT_ADDED, ecommerceProductEventProperties(props));

  if (typedWindow.axon) {
    typedWindow.axon('track', 'add_to_cart', {
      currency: 'USD',
      items: [productEventPropsToAxonItem(props)],
    });
  }
}

export function endlinksViewed(props: ProductEventProps) {
  // Fi event
  analytics.track('Endlinks page', {});

  // Segment standardized ecommerce event
  analytics.track(ECOM_EVENT_PRODUCT_VIEWED, ecommerceProductEventProperties(props));

  if (typedWindow.axon) {
    typedWindow.axon('track', 'view_item', {
      currency: 'USD',
      items: [productEventPropsToAxonItem(props)],
    });
  }
}

export function endlinksAddedToBag(props: ProductEventProps) {
  // Fi event
  analytics.track('Endlinks page > Add to bag', {});

  // Segment standardized ecommerce event
  analytics.track(ECOM_EVENT_PRODUCT_ADDED, ecommerceProductEventProperties(props));

  if (typedWindow.axon) {
    typedWindow.axon('track', 'add_to_cart', {
      currency: 'USD',
      items: [productEventPropsToAxonItem(props)],
    });
  }
}

interface ProductWithVariantEventProps extends ProductEventProps {
  color: ColorOption;
  size: SizeOption;
}

interface CollarKitVariantEventProps extends ProductWithVariantEventProps {
  subscriptionPrice?: number;
}

function ecommerceProductWithVariantEventProperties({
  color,
  size,
  ...productEventProps
}: ProductWithVariantEventProps): SegmentProduct {
  return {
    ...ecommerceProductEventProperties(productEventProps),
    variant: `${size} ${color}`,
  };
}

function collarKitProductWithVariantEventProperties({
  subscriptionPrice,
  ...productWithVariantEventProps
}: CollarKitVariantEventProps): SegmentProduct {
  return {
    ...ecommerceProductWithVariantEventProperties(productWithVariantEventProps),
    subscriptionPrice: subscriptionPrice ? centsToDollarsNumber(subscriptionPrice) : undefined,
  };
}

/**
 * Tracks event when a user lands on the product page for a spare band
 */
export function bandViewed() {
  analytics.track('Band page', {});
}

export function bandColorSwitch(props: ProductWithVariantEventProps) {
  // Fi event
  analytics.track('Band page > Color switch', { new_color: props.color });
}

export function bandSizeSwitch(props: ProductWithVariantEventProps) {
  // Fi event
  analytics.track('Band page > Size switch', { new_size: props.size });
}

export function bandVariantSwitch(props: ProductWithVariantEventProps) {
  // Segment standardized ecommerce event
  analytics.track(ECOM_EVENT_PRODUCT_VIEWED, ecommerceProductWithVariantEventProperties(props));

  if (typedWindow.axon) {
    typedWindow.axon('track', 'view_item', {
      currency: 'USD',
      items: [productEventPropsToAxonItem(props)],
    });
  }
}

export function bandAddedToBag(props: ProductWithVariantEventProps) {
  // Fi event
  analytics.track('Band page > Add to bag', {
    color: props.color,
    size: props.size,
  });

  // Segment standardized ecommerce event
  analytics.track(ECOM_EVENT_PRODUCT_ADDED, ecommerceProductWithVariantEventProperties(props));

  if (typedWindow.axon) {
    typedWindow.axon('track', 'add_to_cart', {
      currency: 'USD',
      items: [productEventPropsToAxonItem(props)],
    });
  }
}

/**
 * Tracks event when a user lands on the product page for an S2 martingale band
 */
export function martingaleBandViewed() {
  analytics.track('Martingale band page', {});
}

export function martingaleBandColorSwitch(props: ProductWithVariantEventProps) {
  // Fi event
  analytics.track('Martingale band page > Color switch', { new_color: props.color });
}

export function martingaleBandSizeSwitch(props: ProductWithVariantEventProps) {
  // Fi event
  analytics.track('Martingale band page > Size switch', { new_size: props.size });
}

export function martingaleBandVariantSwitch(props: ProductWithVariantEventProps) {
  // Segment standardized ecommerce event
  analytics.track(ECOM_EVENT_PRODUCT_VIEWED, ecommerceProductWithVariantEventProperties(props));

  if (typedWindow.axon) {
    typedWindow.axon('track', 'view_item', {
      currency: 'USD',
      items: [productEventPropsToAxonItem(props)],
    });
  }
}

export function martingaleBandAddedToBag(props: ProductWithVariantEventProps) {
  // Fi event
  analytics.track('Martingale band page > Add to bag', {
    color: props.color,
    size: props.size,
  });

  // Segment standardized ecommerce event
  analytics.track(ECOM_EVENT_PRODUCT_ADDED, ecommerceProductWithVariantEventProperties(props));

  if (typedWindow.axon) {
    typedWindow.axon('track', 'add_to_cart', {
      currency: 'USD',
      items: [productEventPropsToAxonItem(props)],
    });
  }
}

/**
 * Tracks event when a user lands on the S3 PDP
 */
export function series3CollarViewed() {
  // Fi event
  analytics.track('Collar page', {});
}

export function series3CollarColorSwitch(props: ProductWithVariantEventProps) {
  // Fi event
  analytics.track('Collar page > Color switch', { new_color: props.color });
}

export function series3CollarSizeSwitch(props: ProductWithVariantEventProps) {
  // Fi event
  analytics.track('Collar page > Size switch', { new_size: props.size });
}

/**
 * Tracks event when a user changes the selected variant on the S3 PDP
 */
export function series3CollarVariantSwitch(props: ProductWithVariantEventProps) {
  // Segment standardized ecommerce event
  analytics.track(ECOM_EVENT_PRODUCT_VIEWED, ecommerceProductWithVariantEventProperties(props));

  if (typedWindow.axon) {
    typedWindow.axon('track', 'view_item', {
      currency: 'USD',
      items: [productEventPropsToAxonItem(props)],
    });
  }
}

export function series3CollarAddedToBag(props: CollarKitVariantEventProps) {
  // Fi event
  analytics.track('Collar page > Add to bag', {
    color: props.color,
    size: props.size,
  });

  // Segment standardized ecommerce event
  analytics.track(ECOM_EVENT_PRODUCT_ADDED, collarKitProductWithVariantEventProperties(props));

  if (typedWindow.axon) {
    typedWindow.axon('track', 'add_to_cart', {
      currency: 'USD',
      items: [productEventPropsToAxonItem(props)],
    });
  }
}

export function series3SubscriptionAddedToBag(props: SubscriptionEventProps) {
  // Fi event
  analytics.track('GPS page > Add to bag', {
    plan: props.productId,
  });

  // Segment standardized ecommerce event
  analytics.track(ECOM_EVENT_PRODUCT_ADDED, ecommerceProductEventProperties(props));

  if (typedWindow.axon) {
    typedWindow.axon('track', 'add_to_cart', {
      currency: 'USD',
      items: [productEventPropsToAxonItem(props)],
    });
  }
}

export function series3CollarActionButtonClick(props: ProductWithVariantEventProps) {
  // Fi event
  analytics.track('Collar page > Action button click', ecommerceProductWithVariantEventProperties(props));
}

export function series3CollarWhatSizeIsRight() {
  // Fi event
  analytics.track('Collar page > What size is right', {});
}

export function series3CollarBuyAsAGift() {
  // Fi event
  analytics.track('Collar page > Buy as a gift', {});
}

export function series3CollarSizingGuideBreedSelectorShown() {
  // Fi event
  analytics.track('Collar page > Sizing guide breed selector shown', {});
}

export function series3CollarSizingGuideBreedSelected(breed: string) {
  // Fi event
  analytics.track('Collar page > Sizing guide breed selected', { breed });
}

export function series3CollarSizingGuideWeightUpdate(previousWeight: number, newWeight: number) {
  // Fi event
  analytics.track('Collar page > Sizing guide weight update', { previousWeight, newWeight });
}

export function series3CollarSizingGuideRecommendationSelected(
  breed: string | undefined,
  weight: number,
  recommendedSize: SizeOption,
) {
  // Fi event
  analytics.track('Collar page > Sizing guide recommendation selected', { breed, weight, recommendedSize });
}

export function series3CollarEditionSwitch(bandVariantGroup: BandVariantGroup) {
  // Fi event
  analytics.track('Collar page > Edition switch', { bandVariantGroup });
}

export function series3CollarFiMakers() {
  // Fi event
  analytics.track('Collar page > Fi Makers', {});
}

export function series3CollarCarouselInteraction() {
  // Fi event
  analytics.track('Collar page > Carousel interaction', {});
}

export function FAQExpand(page: string) {
  // Fi event
  analytics.track(`${page} page > FAQ expand`, {});
}

export function series3CollarFAQChatButtonClick() {
  // Fi event
  analytics.track('Collar page > FAQ > Chat with Fi', {});
}

export function series3CollarMoreReviews() {
  // Fi event
  analytics.track('Collar page > More reviews', {});
}

/**
 * Tracks event when a user lands on the Series 2 collar PDP
 */
export function series2CollarViewed() {
  // Fi event
  analytics.track('Collar page', {});
}

export function series2CollarColorSwitch(props: ProductWithVariantEventProps) {
  // Fi event
  analytics.track('Collar page > Color switch', { new_color: props.color });
}

export function series2CollarSizeSwitch(props: ProductWithVariantEventProps) {
  // Fi event
  analytics.track('Collar page > Size switch', { new_size: props.size });
}

export function series2CollarVariantSwitch(props: ProductWithVariantEventProps) {
  // Segment standardized ecommerce event
  analytics.track(ECOM_EVENT_PRODUCT_VIEWED, ecommerceProductWithVariantEventProperties(props));

  if (typedWindow.axon) {
    typedWindow.axon('track', 'view_item', {
      currency: 'USD',
      items: [productEventPropsToAxonItem(props)],
    });
  }
}

export function series2CollarAddedToBag(props: ProductWithVariantEventProps) {
  // Fi event
  analytics.track('Collar page > Add to bag', {
    color: props.color,
    size: props.size,
  });

  // Segment standardized ecommerce event
  analytics.track(ECOM_EVENT_PRODUCT_ADDED, ecommerceProductWithVariantEventProperties(props));

  if (typedWindow.axon) {
    typedWindow.axon('track', 'add_to_cart', {
      currency: 'USD',
      items: [productEventPropsToAxonItem(props)],
    });
  }
}

export function series2SubscriptionAddedToBag(props: SubscriptionEventProps) {
  // Fi event
  analytics.track('GPS page > Add to bag', {
    plan: props.productId,
  });

  // Segment standardized ecommerce event
  analytics.track(ECOM_EVENT_PRODUCT_ADDED, ecommerceProductEventProperties(props));

  if (typedWindow.axon) {
    typedWindow.axon('track', 'add_to_cart', {
      currency: 'USD',
      items: [productEventPropsToAxonItem(props)],
    });
  }
}

export function giftCardViewed(props: ProductEventProps) {
  // Fi event
  analytics.track('Gift card page', {});

  // Segment standardized ecommerce event
  analytics.track(ECOM_EVENT_PRODUCT_VIEWED, ecommerceProductEventProperties(props));

  if (typedWindow.axon) {
    typedWindow.axon('track', 'view_item', {
      currency: 'USD',
      items: [productEventPropsToAxonItem(props)],
    });
  }
}

export function giftCardSwitch(props: ProductEventProps) {
  // Fi event
  analytics.track('Gift card page > Change package', {});

  // Segment standardized ecommerce event
  analytics.track(ECOM_EVENT_PRODUCT_VIEWED, ecommerceProductEventProperties(props));

  if (typedWindow.axon) {
    typedWindow.axon('track', 'view_item', {
      currency: 'USD',
      items: [productEventPropsToAxonItem(props)],
    });
  }
}

export function giftCardAddedToBag(props: ProductEventProps) {
  // Fi event
  analytics.track('Gift card page > Add to bag', {});

  // Segment standardized ecommerce event
  analytics.track(ECOM_EVENT_PRODUCT_ADDED, ecommerceProductEventProperties(props));

  if (typedWindow.axon) {
    typedWindow.axon('track', 'add_to_cart', {
      currency: 'USD',
      items: [productEventPropsToAxonItem(props)],
    });
  }
}

export interface SubscriptionEventProps extends ProductEventProps {
  productId: string;
}

export function productCategoryNameForEvent(productCategory: ProductCategory): string {
  switch (productCategory) {
    case ProductCategory.BAND:
      return 'band';
    case ProductCategory.CHARGING_BASE:
      return 'base';
    case ProductCategory.COLLAR_KIT:
      return 'collar';
    case ProductCategory.ENDLINK:
      return 'endlinks';
    case ProductCategory.GIFT_CARD:
      return 'gift card';
    case ProductCategory.MICROCHIP:
      return 'nano';
    case ProductCategory.MODULE:
      return 'module';
    case ProductCategory.SUBSCRIPTION:
      return 'subscription';
    case ProductCategory.S2_UPGRADE:
      return 'S2 upgrade';
    case ProductCategory.SUPPLEMENT:
      return 'supplement';
    default:
      expectUnreachable(productCategory);
      throw new Error(`Unknown product category: ${productCategory}`);
  }
}

export function cartItemRemoved({ cartItem }: { cartItem: CartItem }) {
  const skus = skusForCartItem(cartItem);
  const productsAndVariantsBySku = getProductsAndVariantsBySku(store.getState().config.products);

  for (const sku of skus) {
    const productAndVariant = productsAndVariantsBySku.get(sku);
    if (!productAndVariant) {
      continue;
    }

    const { product } = productAndVariant;

    // Fi event
    analytics.track(`Cart page > Remove ${productCategoryNameForEvent(product.category)}`, {});

    // ECom event
    const segmentProduct = skuToSegmentProduct(sku);
    analytics.track(ECOM_EVENT_PRODUCT_REMOVED, segmentProduct);
  }
}

export function cartItemMarkedAsGift() {
  analytics.track(`Cart page > Item Marked as Gift`, {});
}

export function cartItemMarkedAsNotGift() {
  analytics.track(`Cart page > Item Marked as Not Gift`, {});
}

export function orderCompleted(
  cart: Cart,
  cartPricing: CartPricing,
  orderId: string,
  shippingAddress: Address | undefined,
  billingInfo: BillingInfo | undefined,
  additionalProperties: {
    isReturningCustomer: boolean;
    integrations?: {
      [integrationName: string]: boolean;
    };
  },
) {
  const cartItemsArray = Object.values(cart.cartItems);
  const products = cartItemsToSegmentProducts(cart.cartItems);
  const couponCode = cart.couponCode;
  const cartInformation = getCartInformation(cartItemsArray, cartPricing);
  const total = (cartInformation && cartInformation.revenueIncludingFreeTrials) || undefined;
  const shipping = cartInformation?.shipping;
  const tax = cartInformation?.tax;

  // https://segment.com/docs/destinations/google-analytics/#required-steps
  // N.B. Order completed event requires an "orderId"
  analytics.track(
    ECOM_EVENT_ORDER_COMPLETED,
    {
      orderId,
      products,
      total,
      shipping,
      tax,
      code: couponCode,
      new_customer: !additionalProperties.isReturningCustomer,
    },
    { integrations: additionalProperties?.integrations },
  );

  if (shippingAddress && billingInfo) {
    postie.trackPurchase(cartItemsArray, cartPricing, orderId, shippingAddress, billingInfo);
  }

  if (typedWindow.axon) {
    const axonItems = maybeAddAxonSetupFeeItem(cartPricing, cartItemsArray.flatMap(cartItemToAxonItems));
    typedWindow.axon('track', 'purchase', {
      currency: 'USD',
      items: axonItems,
      value: centsToDollarsNumber(cartPricing.totalInCents),
      transaction_id: orderId,
      shipping,
      tax,
    });
  }
}

export const subscriptionPage = {
  viewed() {
    analytics.track('Subscription Page', {});
  },
};

export const subscriptionUpsell = {
  viewed(props: SubscriptionEventProps) {
    analytics.track('Subscription upsell modal', {
      plan: props.productId,
    });
  },

  acceptedUpsell(props: SubscriptionEventProps) {
    analytics.track('Subscription upsell modal > Accepted upsell', {
      plan: props.productId,
    });
  },

  ignoredUpsell(props: SubscriptionEventProps) {
    analytics.track('Subscription upsell modal > Ignored upsell', {
      plan: props.productId,
    });
  },
};

export const cartPage = {
  viewed(cartItems: { [id: string]: CartItem }) {
    // Fi event
    analytics.track('Cart page', {});

    // ECom event
    const products = cartItemsToSegmentProducts(cartItems);
    analytics.track(ECOM_EVENT_CART_VIEWED, { products });
  },

  checkout(cartItems: { [id: string]: CartItem }, cartPricing: CartPricing) {
    // Fi event
    analytics.track('Cart page > Checkout', {});

    // Ecom event
    const products = cartItemsToSegmentProducts(cartItems);
    analytics.track(ECOM_EVENT_CHECKOUT_STARTED, {
      products,
      value: centsToDollars(cartPricing.totalInCents),
      currency: 'USD',
    });

    if (typedWindow.axon) {
      const axonItems = maybeAddAxonSetupFeeItem(cartPricing, Object.values(cartItems).flatMap(cartItemToAxonItems));
      typedWindow.axon('track', 'begin_checkout', {
        currency: 'USD',
        items: axonItems,
        value: centsToDollarsNumber(cartPricing.totalInCents),
      });
    }
  },

  couponApplied(code: string) {
    analytics.track('Cart page > Coupon applied', { code });
  },

  couponError(code: string, message: string) {
    analytics.track('Cart page > Coupon error', {
      code,
      message,
    });
  },

  referralCodeApplied(code: string) {
    analytics.track('Cart page > Referral code applied', { code });
  },

  giftCardApplied(code: string) {
    analytics.track('Cart page > Gift card applied', { code });
  },

  giftCardError(code: string, message: string) {
    analytics.track('Cart page > Gift card error', {
      code,
      message,
    });
  },

  couponRemoved(code: string) {
    analytics.track('Cart page > Coupon removed', { code });
  },

  nonSafariApplePayClick() {
    analytics.track('Cart page > Non Safari Apple pay click', {});
  },

  nonSafariApplePayClose() {
    analytics.track('Cart page > Non Safari Apple pay closed', {});
  },

  applePayBegin() {
    analytics.track('Cart page > Apple pay begin', {});
  },

  applePaySuccess() {
    analytics.track('Cart page > Apple pay success', {});
  },

  applePayError(message: string) {
    analytics.track('Cart page > Apple pay error', { message });
  },

  applePayCancel() {
    analytics.track('Cart page > Apple pay cancel', {});
  },

  paypalUnavailable(products: IProduct[]) {
    analytics.track('Cart page > PayPal unavailable for products', {
      products,
    });
  },

  paypalButtonBegin() {
    analytics.track('Cart page > PayPal button begin', {});
  },

  paypalButtonSuccess() {
    analytics.track('Cart page > PayPal button successfully got a token', {});
  },

  paypalButtonError(message: string) {
    analytics.track('Cart page > PayPal button error', { message });
  },

  paypalButtonCancel() {
    analytics.track('Cart page > PayPal button cancel', {});
  },
};

function checkoutStepCompleted(cartItems: CartItem[], cartPricing: CartPricing) {
  const cartInformation = getCartInformation(cartItems, cartPricing);
  const segmentProducts = cartItems.flatMap(cartItemToSegmentProducts);
  analytics.track(ECOM_EVENT_CHECKOUT_STEP_COMPLETED, {
    products: segmentProducts,
    ...(cartInformation && {
      value: cartInformation.revenueIncludingFreeTrials,
      currency: 'USD',
    }),
  });
}

export const shipping = {
  viewed() {
    analytics.track('Shipping page', {});
  },

  continue(cartItems: CartItem[], cartPricing: CartPricing) {
    analytics.track('Shipping page > Continue', {});
    checkoutStepCompleted(cartItems, cartPricing);
  },

  continueError(message?: string) {
    analytics.track('Shipping page > Continue Error', {
      message,
    });
  },

  verifyAddressViewed() {
    analytics.track('Shipping page > Verify address', {});
  },

  verifyAddressConfirmed() {
    analytics.track('Shipping page > Verify address confirmed', {});
  },

  verifyAddressEdit() {
    analytics.track('Shipping page > Verify address re-edit', {});
  },
};

export const shippingOptions = {
  viewed() {
    analytics.track('Shipping options', {});
  },

  continue() {
    analytics.track('Shipping options > Continue', {});
  },
};

export const payment = {
  viewed() {
    analytics.track('Payment page', {});
  },

  continue(cartItems: { [id: string]: CartItem }) {
    // Fi event
    analytics.track('Payment page > Payment Success', {});

    // Ecom event
    const products = cartItemsToSegmentProducts(cartItems);
    analytics.track(ECOM_EVENT_PAYMENT_INFO_ENTERED, {
      products,
    });
  },

  continueError(message?: string) {
    analytics.track('Payment page > Payment Error', { message });
  },

  paypalUnavailable(products: IProduct[]) {
    analytics.track('Payment page > PayPal unavailable for products', {
      products,
    });
  },

  paypalTokenSuccess() {
    analytics.track('Payment page > PayPal success', {});
  },

  paypalTokenError(message: string) {
    analytics.track('Payment page > PayPal error', { message });
  },

  paypalUnlink() {
    analytics.track('Payment page > PayPal unlink', {});
  },

  paypalButtonBegin() {
    analytics.track('Payment page > PayPal button begin', {});
  },

  paypalButtonSuccess() {
    analytics.track('Payment page > PayPal button successfully got a token', {});
  },

  paypalButtonError(message: string) {
    analytics.track('Payment page > PayPal button error', { message });
  },

  paypalButtonCancel() {
    analytics.track('Payment page > PayPal button cancel', {});
  },
};

export const confirmation = {
  viewed() {
    analytics.track('Confirmation Page', {});
  },

  finalConfirm(cartItems: CartItem[], cartPricing: CartPricing) {
    analytics.track('Confirmation Page > Final Confirm', {});
    checkoutStepCompleted(cartItems, cartPricing);
  },

  placeOrder(
    upgradeNewSku: SubscriptionOption,
    context: PaymentContext,
    petId?: string | null,
    upgradeOldSku?: string | null,
  ) {
    analytics.track(`${context} > Place Order`, { petId, upgradeOldSku, upgradeNewSku });
  },

  error(message?: string) {
    analytics.track('Confirmation Page > Error', { message });
  },
};

const SUBSCRIBE_AND_SAVE = 'Supplements Subscribe & Save';
export const supplements = {
  viewed() {
    analytics.track('Supplements page', {});
  },
  getItClicked(flow: SupplementCheckoutFlow) {
    analytics.track(`Supplements ${flow} > Get it`, {});
  },
  purchaseSucceeded(flow: SupplementCheckoutFlow) {
    analytics.track(`Supplements ${flow} > Get it > Purchase successfully went through`, {});
  },
  noThanksClicked() {
    analytics.track('Supplements page > No, thanks', {});
  },
  openPlanSelection(flow: SupplementCheckoutFlow) {
    analytics.track(`Supplements ${flow} > Plan Selection`, {
      context: getSupplementsManagementEventContext(),
    });
  },
  closePlanSelection(flow: SupplementCheckoutFlow) {
    analytics.track(`Supplements ${flow} > Plan Selection > Close`, {
      context: getSupplementsManagementEventContext(),
    });
  },
  // We're removing subscribeAndSave from the in-app experience, but will keep 'ecomm'
  // in the event name so the dashboards reliant on analytic events don't break.
  subscribeAndSave: {
    buyOneBagSelected() {
      analytics.track(`${SUBSCRIBE_AND_SAVE} ecomm > Buy one bag option selected`, {});
    },
    subscribeAndSaveSelected() {
      analytics.track(`${SUBSCRIBE_AND_SAVE} ecomm > Subscribe and save option selected`, {});
    },
    purchaseButtonClicked(buttonCopy: string) {
      analytics.track(`${SUBSCRIBE_AND_SAVE} ecomm > ${buttonCopy} button clicked`, {});
    },
    purchaseSucceeded() {
      analytics.track(`${SUBSCRIBE_AND_SAVE} ecomm > Purchase successfully went through`, {});
    },
    noThanksClicked() {
      analytics.track(`${SUBSCRIBE_AND_SAVE} ecomm > No, thanks`, {});
    },
    purchaseConfirmationModalContinue(buttonCopy: string) {
      analytics.track(
        `${SUBSCRIBE_AND_SAVE} ecomm > ${buttonCopy} button clicked > Confirmation modal viewed > Confirm`,
        {},
      );
    },
    purchaseConfirmationModalGoBack(buttonCopy: string) {
      analytics.track(
        `${SUBSCRIBE_AND_SAVE} ecomm > ${buttonCopy} button clicked > Confirmation modal viewed > No, go back`,
        {},
      );
    },
  },
};

export const thankYou = {
  viewed() {
    analytics.track('Thank you page', {});
  },

  setPasswordSuccess() {
    analytics.track('Thank you page > Set password success', {});
  },

  setPasswordFailure() {
    analytics.track('Thank you page > Set password failure', {});
  },

  downloadLinkDetails(): EventDetails {
    return { eventName: 'Thank you page > Download clicked' };
  },

  surveySubmit({ userId, selectedValue, email }: { userId?: string; selectedValue: string; email?: string }): void {
    analytics.track('Thank you page > Survey Submitted', {
      userId,
      selectedValue,
      email,
    });
  },
};

export const referrals = {
  codeCopied() {
    analytics.track('Referrals Page > Code Copied', {});
  },

  shareClicked(nativeShare: boolean) {
    analytics.track('Referrals Page > Share Clicked', { nativeShare });
  },
};

export const referralRedirect = {
  loaded(code: string) {
    analytics.track('Referral Redirect > Loaded', { code });
  },
};

export const accessories = {
  fiMakerClick: (name: string): EventDetails => ({
    eventName: `Accessories page > Fi Maker clicked`,
    eventProps: {
      name,
    },
  }),
};

interface InAppCancellationProps {
  petId?: string;
  cancellationReason?: gqlTypes.zendeskCancellationReason;
  replacementReason?: string;
  otherReasonText?: string;
  oldSku?: string;
  newSku?: string;
  subscriptionCategory?: SubscriptionCategory;
}

function getInAppCancellationEventProperties(props: InAppCancellationProps) {
  return {
    petId: props.petId,
    cancellationReason: props.cancellationReason,
    cancellationText: props.otherReasonText,
    replacementReason: props.replacementReason,
    oldSku: props.oldSku,
    newSku: props.newSku,
    subscriptionCategory: props.subscriptionCategory,
  };
}

// Returns an object containing Segment context fields.
function getInAppCancellationEventContext() {
  // Params search string looks like "?platform=android&app_version=3.5.41-internal"
  const paramsString = window.location.search;
  const searchParams = new URLSearchParams(paramsString);
  return {
    os: { name: searchParams.get('platform') },
    app: { version: searchParams.get('app_version') },
  };
}

export const inAppCancellation = {
  manageMembershipClicked(props: InAppCancellationProps) {
    analytics.track('Membership > Manage membership clicked', getInAppCancellationEventProperties(props), {
      context: getInAppCancellationEventContext(),
    });
  },
  manageMembershipBack(props: InAppCancellationProps) {
    analytics.track('Membership > Back', getInAppCancellationEventProperties(props), {
      context: getInAppCancellationEventContext(),
    });
  },
  manageMembershipClose(props: InAppCancellationProps) {
    analytics.track('Membership > Close', getInAppCancellationEventProperties(props), {
      context: getInAppCancellationEventContext(),
    });
  },
  updatePaymentMethodClicked(props: InAppCancellationProps) {
    analytics.track('Membership > Update payment method clicked', getInAppCancellationEventProperties(props), {
      context: getInAppCancellationEventContext(),
    });
  },
  updatePaymentBack(props: InAppCancellationProps) {
    analytics.track('Membership > Update payment > Back', getInAppCancellationEventProperties(props), {
      context: getInAppCancellationEventContext(),
    });
  },
  updatePaymentClose(props: InAppCancellationProps) {
    analytics.track('Membership > Update payment > Close', getInAppCancellationEventProperties(props), {
      context: getInAppCancellationEventContext(),
    });
  },
  renewMembership(props: InAppCancellationProps) {
    analytics.track('Membership > Renew', getInAppCancellationEventProperties(props), {
      context: getInAppCancellationEventContext(),
    });
  },
  renewMembershipContinue(props: InAppCancellationProps) {
    analytics.track('Renew Membership > Continue', getInAppCancellationEventProperties(props), {
      context: getInAppCancellationEventContext(),
    });
  },
  renewMembershipBack(props: InAppCancellationProps) {
    analytics.track('Renew Membership > Back', getInAppCancellationEventProperties(props), {
      context: getInAppCancellationEventContext(),
    });
  },
  renewMembershipClose(props: InAppCancellationProps) {
    analytics.track('Renew Membership > Close', getInAppCancellationEventProperties(props), {
      context: getInAppCancellationEventContext(),
    });
  },
  renewConfirmationDoneClicked(props: InAppCancellationProps) {
    analytics.track('Renew > Done', getInAppCancellationEventProperties(props), {
      context: getInAppCancellationEventContext(),
    });
  },
  resumeConfirmationDoneClicked(props: InAppCancellationProps) {
    analytics.track('Resume > Done', getInAppCancellationEventProperties(props), {
      context: getInAppCancellationEventContext(),
    });
  },
  requestToCancelMembershipClicked(props: InAppCancellationProps) {
    analytics.track(
      'Manage Membership > Request to cancel membership clicked',
      getInAppCancellationEventProperties(props),
      {
        context: getInAppCancellationEventContext(),
      },
    );
  },
  cancelFromReasonPage(props: InAppCancellationProps) {
    analytics.track('Cancellation Reasons > Cancel', getInAppCancellationEventProperties(props), {
      context: getInAppCancellationEventContext(),
    });
  },
  backFromReasonPage(props: InAppCancellationProps) {
    analytics.track('Manage Membership > Cancel Reason > Back', getInAppCancellationEventProperties(props), {
      context: getInAppCancellationEventContext(),
    });
  },
  closeFromReasonPage(props: InAppCancellationProps) {
    analytics.track('Manage Membership > Cancel Reason > Close', getInAppCancellationEventProperties(props), {
      context: getInAppCancellationEventContext(),
    });
  },
  upgradeToS3Clicked(props: InAppCancellationProps) {
    analytics.track('Manage Membership > Upgrade to S3 clicked', getInAppCancellationEventProperties(props), {
      context: getInAppCancellationEventContext(),
    });
  },
  changeMembershipSaveClicked(props: InAppCancellationProps) {
    analytics.track('Manage Membership > Change membership save clicked', getInAppCancellationEventProperties(props), {
      context: getInAppCancellationEventContext(),
    });
  },
  changeMembershipBack(props: InAppCancellationProps) {
    analytics.track('Manage Membership > Change membership > Back', getInAppCancellationEventProperties(props), {
      context: getInAppCancellationEventContext(),
    });
  },
  changeMembershipClose(props: InAppCancellationProps) {
    analytics.track('Manage Membership > Change membership > Close', getInAppCancellationEventProperties(props), {
      context: getInAppCancellationEventContext(),
    });
  },
  cancellationTicketConfirmationDoneClicked(props: InAppCancellationProps) {
    analytics.track(
      'Manage Membership > Cancellation ticket confirmation done clicked',
      getInAppCancellationEventProperties(props),
      {
        context: getInAppCancellationEventContext(),
      },
    );
  },
  cancellationReasonSelectedAndClicked(props: InAppCancellationProps) {
    analytics.track(
      'Manage Membership > Cancellation reason selected and clicked',
      getInAppCancellationEventProperties(props),
      {
        context: getInAppCancellationEventContext(),
      },
    );
  },
  cancellationReasonOtherSelectedAndClicked(props: InAppCancellationProps) {
    analytics.track('Manage Membership > Other Reason > Cancel', getInAppCancellationEventProperties(props), {
      context: getInAppCancellationEventContext(),
    });
  },
  cancellationReasonOtherBack(props: InAppCancellationProps) {
    analytics.track('Manage Membership > Other Reason > Back', getInAppCancellationEventProperties(props), {
      context: getInAppCancellationEventContext(),
    });
  },
  requestToCancelMembershipSubmitted(props: InAppCancellationProps) {
    analytics.track(
      'Manage Membership > Request to cancel membership submitted',
      getInAppCancellationEventProperties(props),
      {
        context: getInAppCancellationEventContext(),
      },
    );
  },
  upgradeS2Cancel(props: InAppCancellationProps) {
    analytics.track('Upgrade your series 2 collar > Cancel clicked', getInAppCancellationEventProperties(props), {
      context: getInAppCancellationEventContext(),
    });
  },
  upgradeS2Back(props: InAppCancellationProps) {
    analytics.track('Upgrade your series 2 collar > Back', getInAppCancellationEventProperties(props), {
      context: getInAppCancellationEventContext(),
    });
  },
  upgradeS2Close(props: InAppCancellationProps) {
    analytics.track('Upgrade your series 2 collar > Close', getInAppCancellationEventProperties(props), {
      context: getInAppCancellationEventContext(),
    });
  },
  upgradeS2TicketSubmitted(props: InAppCancellationProps) {
    analytics.track(
      'Upgrade your series 2 collar > Cancel ticket created',
      getInAppCancellationEventProperties(props),
      {
        context: getInAppCancellationEventContext(),
      },
    );
  },
  upgradeS2CheckoutFlow(props: InAppCancellationProps, pageName: string) {
    analytics.track(`S2 Upgrade > ${pageName}`, getInAppCancellationEventProperties(props), {
      context: getInAppCancellationEventContext(),
    });
  },
  upgradeS2CheckoutV2Flow(props: InAppCancellationProps, pageName: string) {
    analytics.track(`Upgrade V2 > ${pageName}`, getInAppCancellationEventProperties(props), {
      context: getInAppCancellationEventContext(),
    });
  },
  chatWithCx(props: InAppCancellationProps) {
    analytics.track('Contact CX > Chat', getInAppCancellationEventProperties(props), {
      context: getInAppCancellationEventContext(),
    });
  },
  callCx(props: InAppCancellationProps) {
    analytics.track('Contact CX > Call', getInAppCancellationEventProperties(props), {
      context: getInAppCancellationEventContext(),
    });
  },
  cancelFromContactPage(props: InAppCancellationProps) {
    analytics.track('Contact CX > Cancel', getInAppCancellationEventProperties(props), {
      context: getInAppCancellationEventContext(),
    });
  },
  contactCxBack(props: InAppCancellationProps) {
    analytics.track('Contact CX > Back', getInAppCancellationEventProperties(props), {
      context: getInAppCancellationEventContext(),
    });
  },
  contactCxClose(props: InAppCancellationProps) {
    analytics.track('Contact CX > Close', getInAppCancellationEventProperties(props), {
      context: getInAppCancellationEventContext(),
    });
  },
  cancelFromFreeMonthPage(props: InAppCancellationProps) {
    analytics.track('Free month > cancel', getInAppCancellationEventProperties(props), {
      context: getInAppCancellationEventContext(),
    });
  },
  claimFreeMonth(props: InAppCancellationProps) {
    analytics.track('Free month > claim', getInAppCancellationEventProperties(props), {
      context: getInAppCancellationEventContext(),
    });
  },
  claimFreeMonthDone(props: InAppCancellationProps) {
    analytics.track('Free month > claim > done', getInAppCancellationEventProperties(props), {
      context: getInAppCancellationEventContext(),
    });
  },
  claimFreeMonthBack(props: InAppCancellationProps) {
    analytics.track('Free month > claim > back', getInAppCancellationEventProperties(props), {
      context: getInAppCancellationEventContext(),
    });
  },
  claimFreeMonthClose(props: InAppCancellationProps) {
    analytics.track('Free month > claim > close', getInAppCancellationEventProperties(props), {
      context: getInAppCancellationEventContext(),
    });
  },
  cancelFromPauseMembershipPage(props: InAppCancellationProps) {
    analytics.track('Pause membership > cancel', getInAppCancellationEventProperties(props), {
      context: getInAppCancellationEventContext(),
    });
  },
  claimPauseMembership(props: InAppCancellationProps) {
    analytics.track('Pause membership > claim', getInAppCancellationEventProperties(props), {
      context: getInAppCancellationEventContext(),
    });
  },
  claimPauseMembershipDone(props: InAppCancellationProps) {
    analytics.track('Pause membership > claim > done', getInAppCancellationEventProperties(props), {
      context: getInAppCancellationEventContext(),
    });
  },
  claimPauseMembershipBack(props: InAppCancellationProps) {
    analytics.track('Pause membership > claim > back', getInAppCancellationEventProperties(props), {
      context: getInAppCancellationEventContext(),
    });
  },
  claimPauseMembershipClose(props: InAppCancellationProps) {
    analytics.track('Pause membership > claim > close', getInAppCancellationEventProperties(props), {
      context: getInAppCancellationEventContext(),
    });
  },
  resumeMembership(props: InAppCancellationProps) {
    analytics.track('Resume membership > confirm', getInAppCancellationEventProperties(props), {
      context: getInAppCancellationEventContext(),
    });
  },
  multidogDiscountBack(props: InAppCancellationProps) {
    analytics.track('Multidog Discount > Back', getInAppCancellationEventProperties(props), {
      context: getInAppCancellationEventContext(),
    });
  },
  multidogDiscountClose(props: InAppCancellationProps) {
    analytics.track('Multidog Discount > Close', getInAppCancellationEventProperties(props), {
      context: getInAppCancellationEventContext(),
    });
  },
  multidogDiscountCancelClicked(props: InAppCancellationProps) {
    analytics.track('Multidog Discount > Cancel', getInAppCancellationEventProperties(props), {
      context: getInAppCancellationEventContext(),
    });
  },
  multidogDiscountApplied(props: InAppCancellationProps) {
    analytics.track('Multidog Discount > Applied', getInAppCancellationEventProperties(props), {
      context: getInAppCancellationEventContext(),
    });
  },
  multidogDiscountConfirmationDoneClicked(props: InAppCancellationProps) {
    analytics.track('Multidog Discount > Applied > Done', getInAppCancellationEventProperties(props), {
      context: getInAppCancellationEventContext(),
    });
  },
  dogDoesNotRunAwayKeepMembership(props: InAppCancellationProps) {
    analytics.track('Dog Does Not Run Away > Keep Membership', getInAppCancellationEventProperties(props), {
      context: getInAppCancellationEventContext(),
    });
  },
  dogDoesNotRunAwayContinue(props: InAppCancellationProps) {
    analytics.track('Dog Does Not Run Away > Continue', getInAppCancellationEventProperties(props), {
      context: getInAppCancellationEventContext(),
    });
  },
  dogDoesNotRunAwayCancel(props: InAppCancellationProps) {
    analytics.track('Dog Does Not Run Away > Cancel', getInAppCancellationEventProperties(props), {
      context: getInAppCancellationEventContext(),
    });
  },
  dogDoesNotRunAwayBack(props: InAppCancellationProps, page: number) {
    analytics.track(`Dog Does Not Run Away > P${page} > Back`, getInAppCancellationEventProperties(props), {
      context: getInAppCancellationEventContext(),
    });
  },
  dogDoesNotRunAwayClose(props: InAppCancellationProps, page: number) {
    analytics.track(`Dog Does Not Run Away > P${page} > Close`, getInAppCancellationEventProperties(props), {
      context: getInAppCancellationEventContext(),
    });
  },
  dogDoesNotRunAwayNext(props: InAppCancellationProps, page: number) {
    analytics.track(`Dog Does Not Run Away > P${page} > Next`, getInAppCancellationEventProperties(props), {
      context: getInAppCancellationEventContext(),
    });
  },
  replaceCollarBack(props: InAppCancellationProps) {
    analytics.track(`Physically Damaged > Back`, getInAppCancellationEventProperties(props), {
      context: getInAppCancellationEventContext(),
    });
  },
  replaceCollarClose(props: InAppCancellationProps) {
    analytics.track(`Physically Damaged > Close`, getInAppCancellationEventProperties(props), {
      context: getInAppCancellationEventContext(),
    });
  },
  replacementTicketSubmitted(props: InAppCancellationProps) {
    analytics.track('Physically Damaged > Submit Replacement Request', getInAppCancellationEventProperties(props), {
      context: getInAppCancellationEventContext(),
    });
  },
  replacementTicketConfirmationDoneClicked(props: InAppCancellationProps) {
    analytics.track(
      'Physically Damaged > Submit Replacement Request > Done',
      getInAppCancellationEventProperties(props),
      {
        context: getInAppCancellationEventContext(),
      },
    );
  },
  series3UpgradeSplashContinueClicked(props: InAppCancellationProps) {
    analytics.track('Membership > Series 3 Upgrade Splash > Continue', getInAppCancellationEventProperties(props), {
      context: getInAppCancellationEventContext(),
    });
  },
  series2UpgradePageUpgradeClicked(props: InAppCancellationProps) {
    analytics.track('Upgrade your Series 2 collar Page > Upgrade Now', getInAppCancellationEventProperties(props), {
      context: getInAppCancellationEventContext(),
    });
  },
  upgradeToS3Checkout(props: InAppCancellationProps) {
    analytics.track(
      'Upgrade Now > S3 Plan Selected > Continue > Checkout',
      getInAppCancellationEventProperties(props),
      {
        context: getInAppCancellationEventContext(),
      },
    );
  },
  upgradeToS3CheckoutComplete(props: InAppCancellationProps) {
    analytics.track(
      'Upgrade Now > S3 Plan Selected > Continue > Checkout > Thank You Page',
      getInAppCancellationEventProperties(props),
      {
        context: getInAppCancellationEventContext(),
      },
    );
  },
  downgradeS2Cancel(props: InAppCancellationProps) {
    analytics.track('Downgrade S2 > Cancel', getInAppCancellationEventProperties(props), {
      context: getInAppCancellationEventContext(),
    });
  },
  downgradeS2Back(props: InAppCancellationProps) {
    analytics.track('Downgrade S2 > Back', getInAppCancellationEventProperties(props), {
      context: getInAppCancellationEventContext(),
    });
  },
  downgradeS2Close(props: InAppCancellationProps) {
    analytics.track('Downgrade S2 > Close', getInAppCancellationEventProperties(props), {
      context: getInAppCancellationEventContext(),
    });
  },
  downgradeS3SelectPlan(props: InAppCancellationProps) {
    analytics.track('Downgrade S3 > Select Plan', getInAppCancellationEventProperties(props), {
      context: getInAppCancellationEventContext(),
    });
  },
  downgradeS3Cancel(props: InAppCancellationProps) {
    analytics.track('Downgrade S3 > Cancel', getInAppCancellationEventProperties(props), {
      context: getInAppCancellationEventContext(),
    });
  },
  downgradeS3Back(props: InAppCancellationProps) {
    analytics.track('Downgrade S3 > Back', getInAppCancellationEventProperties(props), {
      context: getInAppCancellationEventContext(),
    });
  },
  downgradeS3Close(props: InAppCancellationProps) {
    analytics.track('Downgrade S3 > Close', getInAppCancellationEventProperties(props), {
      context: getInAppCancellationEventContext(),
    });
  },
  downgradeS3ConfirmationDone(props: InAppCancellationProps) {
    analytics.track('Downgrade S3 > Confirmation > Done', getInAppCancellationEventProperties(props), {
      context: getInAppCancellationEventContext(),
    });
  },
  tooExpensiveKeepMembership(props: InAppCancellationProps) {
    analytics.track('Too Expensive > Keep Membership', getInAppCancellationEventProperties(props), {
      context: getInAppCancellationEventContext(),
    });
  },
  tooExpensiveContinue(props: InAppCancellationProps) {
    analytics.track('Too Expensive > Continue', getInAppCancellationEventProperties(props), {
      context: getInAppCancellationEventContext(),
    });
  },
  tooExpensiveCancel(props: InAppCancellationProps) {
    analytics.track('Too Expensive > Cancel', getInAppCancellationEventProperties(props), {
      context: getInAppCancellationEventContext(),
    });
  },
  tooExpensiveBack(props: InAppCancellationProps, page: number) {
    analytics.track(`Too Expensive > P${page} > Back`, getInAppCancellationEventProperties(props), {
      context: getInAppCancellationEventContext(),
    });
  },
  tooExpensiveClose(props: InAppCancellationProps, page: number) {
    analytics.track(`Too Expensive > P${page} > Close`, getInAppCancellationEventProperties(props), {
      context: getInAppCancellationEventContext(),
    });
  },
  tooExpensiveNext(props: InAppCancellationProps, page: number) {
    analytics.track(`Too Expensive > P${page} > Next`, getInAppCancellationEventProperties(props), {
      context: getInAppCancellationEventContext(),
    });
  },
  pageLoad(props: InAppCancellationProps, pageName: string) {
    analytics.track(`Page Load: ${pageName}`, getInAppCancellationEventProperties(props), {
      context: getInAppCancellationEventContext(),
    });
  },
  subscriptionSplashButtonsLoaded(props: InAppCancellationProps, buttons: string) {
    analytics.track(`Subscription Splash Buttons Loaded: ${buttons}`, getInAppCancellationEventProperties(props), {
      context: getInAppCancellationEventContext(),
    });
  },
  tellUsMoreBack(props: InAppCancellationProps) {
    analytics.track(`Tell Us More > Back`, getInAppCancellationEventProperties(props), {
      context: getInAppCancellationEventContext(),
    });
  },
  tellUsMoreClose(props: InAppCancellationProps) {
    analytics.track(`Tell Us More > Close`, getInAppCancellationEventProperties(props), {
      context: getInAppCancellationEventContext(),
    });
  },
  tellUsMoreContinue(props: InAppCancellationProps) {
    analytics.track(`Tell Us More > Continue`, getInAppCancellationEventProperties(props), {
      context: getInAppCancellationEventContext(),
    });
  },
  meetMakersBack(props: InAppCancellationProps) {
    analytics.track(`Meet Makers > Back`, getInAppCancellationEventProperties(props), {
      context: getInAppCancellationEventContext(),
    });
  },
  meetMakersClose(props: InAppCancellationProps) {
    analytics.track(`Meet Makers > Close`, getInAppCancellationEventProperties(props), {
      context: getInAppCancellationEventContext(),
    });
  },
  meetMakersContinue(props: InAppCancellationProps) {
    analytics.track(`Meet Makers > Continue`, getInAppCancellationEventProperties(props), {
      context: getInAppCancellationEventContext(),
    });
  },
  specialOffersBack(props: InAppCancellationProps) {
    analytics.track(`Special Offers > Back`, getInAppCancellationEventProperties(props), {
      context: getInAppCancellationEventContext(),
    });
  },
  specialOffersClose(props: InAppCancellationProps) {
    analytics.track(`Special Offers > Close`, getInAppCancellationEventProperties(props), {
      context: getInAppCancellationEventContext(),
    });
  },
  specialOffersContinue(props: InAppCancellationProps) {
    analytics.track(`Special Offers > Continue`, getInAppCancellationEventProperties(props), {
      context: getInAppCancellationEventContext(),
    });
  },
  cancelFromSpecialOffersPage(props: InAppCancellationProps) {
    analytics.track('Special Offers > Continue with cancellation', getInAppCancellationEventProperties(props), {
      context: getInAppCancellationEventContext(),
    });
  },
  specialOffersClaim(props: InAppCancellationProps, offer: string) {
    analytics.track(`Special Offers > Claim special offer: ${offer}`, getInAppCancellationEventProperties(props), {
      context: getInAppCancellationEventContext(),
    });
  },
  specialOffersConfirmationDoneClicked(props: InAppCancellationProps) {
    analytics.track('Special Offers Confirmation > Done', getInAppCancellationEventProperties(props), {
      context: getInAppCancellationEventContext(),
    });
  },
  meetMakersExplore(props: InAppCancellationProps) {
    analytics.track(`Meet Makers > Explore`, getInAppCancellationEventProperties(props), {
      context: getInAppCancellationEventContext(),
    });
  },
  keepMembershipConfirmationBack(props: InAppCancellationProps) {
    analytics.track('Keep Membership Confirmation > Back', getInAppCancellationEventProperties(props), {
      context: getInAppCancellationEventContext(),
    });
  },
  keepMembershipConfirmationClose(props: InAppCancellationProps) {
    analytics.track('Keep Membership Confirmation > Close', getInAppCancellationEventProperties(props), {
      context: getInAppCancellationEventContext(),
    });
  },
  cancelConfirmationModalContinue(props: InAppCancellationProps, pageName: string) {
    analytics.track(`${pageName} > Cancel Confirmation > Continue`, getInAppCancellationEventProperties(props), {
      context: getInAppCancellationEventContext(),
    });
  },
  cancelConfirmationModalGoBack(props: InAppCancellationProps, pageName: string) {
    analytics.track(`${pageName} > Cancel Confirmation > Go Back`, getInAppCancellationEventProperties(props), {
      context: getInAppCancellationEventContext(),
    });
  },
  renewConfirmationModalContinue(props: InAppCancellationProps, pageName: string) {
    analytics.track(`${pageName} > Renew Confirmation > Continue`, getInAppCancellationEventProperties(props), {
      context: getInAppCancellationEventContext(),
    });
  },
  renewConfirmationModalGoBack(props: InAppCancellationProps, pageName: string) {
    analytics.track(`${pageName} > Renew Confirmation > Go Back`, getInAppCancellationEventProperties(props), {
      context: getInAppCancellationEventContext(),
    });
  },
  troubleshootButtonClicked(props: TroubleshootProps) {
    analytics.track(`Cancel > Need collar help? Troubleshoot`, getTroubleshootEventProperties(props), {
      context: getTroubleshootEventContext(),
    });
  },
};

interface SupplementsManagementProps {
  subscriptionId?: string;
  cancellationReason?: gqlTypes.supplementCancellationReason;
  cancellationText?: string;
  oldSku?: string;
  newSku?: string;
}

function getSupplementsManagementEventProperties(props: SupplementsManagementProps) {
  return {
    subscriptionId: props.subscriptionId,
    cancellationReason: props.cancellationReason,
    cancellationText: props.cancellationText,
    oldSku: props.oldSku,
    newSku: props.newSku,
  };
}

// Returns an object containing Segment context fields.
function getSupplementsManagementEventContext() {
  // Params search string looks like "?platform=android&app_version=3.5.41-internal"
  const paramsString = window.location.search;
  const searchParams = new URLSearchParams(paramsString);
  return {
    os: { name: searchParams.get('platform') },
    app: { version: searchParams.get('app_version') },
  };
}

export const supplementsManagement = {
  goBackFromPage(props: SupplementsManagementProps, pageName: string) {
    analytics.track(`Supplements > ${pageName} > Back`, getSupplementsManagementEventProperties(props), {
      context: getSupplementsManagementEventContext(),
    });
  },
  closePage(props: SupplementsManagementProps, pageName: string) {
    analytics.track(`Supplements > ${pageName} > Close`, getSupplementsManagementEventProperties(props), {
      context: getSupplementsManagementEventContext(),
    });
  },
  pageLoad(props: SupplementsManagementProps, pageName: string) {
    analytics.track(`Supplements > Page Load: ${pageName}`, getSupplementsManagementEventProperties(props), {
      context: getSupplementsManagementEventContext(),
    });
  },
  cancelSubscription(props: SupplementsManagementProps) {
    analytics.track(
      `Supplements > Manage > Update Cadence > Cancel tapped`,
      getSupplementsManagementEventProperties(props),
      {
        context: getSupplementsManagementEventContext(),
      },
    );
  },
  cancelConfirmationModalContinue(props: SupplementsManagementProps, pageName: string) {
    analytics.track(
      `Supplements > ${pageName} > Cancel Confirmation > Continue`,
      getSupplementsManagementEventProperties(props),
      {
        context: getSupplementsManagementEventContext(),
      },
    );
  },
  cancelConfirmationModalGoBack(props: SupplementsManagementProps, pageName: string) {
    analytics.track(
      `Supplements > ${pageName} > Cancel Confirmation > Go Back`,
      getSupplementsManagementEventProperties(props),
      {
        context: getSupplementsManagementEventContext(),
      },
    );
  },
  purchaseConfirmationModalContinue(props: SupplementsManagementProps, pageName: string) {
    analytics.track(
      `Supplements > ${pageName} > Purchase Confirmation > Purchase`,
      getSupplementsManagementEventProperties(props),
      {
        context: getSupplementsManagementEventContext(),
      },
    );
  },
  purchaseConfirmationModalGoBack(props: SupplementsManagementProps, pageName: string) {
    analytics.track(
      `Supplements > ${pageName} > Purchase Confirmation > Go Back`,
      getSupplementsManagementEventProperties(props),
      {
        context: getSupplementsManagementEventContext(),
      },
    );
  },
  addSupplementsTapped(props: SupplementsManagementProps) {
    analytics.track(`Supplements > Add Supplements tapped`, getSupplementsManagementEventProperties(props), {
      context: getSupplementsManagementEventContext(),
    });
  },
  manageSubscription(props: SupplementsManagementProps) {
    analytics.track(`Supplements > Manage tapped`, getSupplementsManagementEventProperties(props), {
      context: getSupplementsManagementEventContext(),
    });
  },
  shipmentFrequencyTapped(props: SupplementsManagementProps) {
    analytics.track(
      `Supplements > Manage > Shipment frequency tapped`,
      getSupplementsManagementEventProperties(props),
      {
        context: getSupplementsManagementEventContext(),
      },
    );
  },
  shipmentFrequencyUpdated(props: SupplementsManagementProps) {
    analytics.track(
      `Supplements > Manage > Subscription frequency updated`,
      getSupplementsManagementEventProperties(props),
      {
        context: getSupplementsManagementEventContext(),
      },
    );
  },
  updateShipmentDateTapped(props: SupplementsManagementProps, referrer?: string) {
    analytics.track(
      `Supplements > Manage > ${referrer ? `${referrer} > ` : ''}Update shipment date tapped`,
      getSupplementsManagementEventProperties(props),
      {
        context: getSupplementsManagementEventContext(),
      },
    );
  },
  setShipDate(props: SupplementsManagementProps) {
    analytics.track(`Supplements > Manage > Shipment date updated`, getSupplementsManagementEventProperties(props), {
      context: getSupplementsManagementEventContext(),
    });
  },
  sendNowTapped(props: SupplementsManagementProps) {
    analytics.track(`Supplements > Manage > Send now tapped`, getSupplementsManagementEventProperties(props), {
      context: getSupplementsManagementEventContext(),
    });
  },
  defaultSendNowAccepted(props: SupplementsManagementProps) {
    analytics.track(
      `Supplements > Manage > Send now > Default accepted`,
      getSupplementsManagementEventProperties(props),
      {
        context: getSupplementsManagementEventContext(),
      },
    );
  },
  skipShipmentTapped(props: SupplementsManagementProps) {
    analytics.track(`Supplements > Manage > Skip shipment tapped`, getSupplementsManagementEventProperties(props), {
      context: getSupplementsManagementEventContext(),
    });
  },
  modalCloseEvent(props: SupplementsManagementProps, modalName: string) {
    analytics.track(
      `Supplements > Manage > ${modalName} > Close modal`,
      getSupplementsManagementEventProperties(props),
      {
        context: getSupplementsManagementEventContext(),
      },
    );
  },
  defaultSkipAccepted(props: SupplementsManagementProps) {
    analytics.track(
      `Supplements > Manage > Skip shipment > Default accepted`,
      getSupplementsManagementEventProperties(props),
      {
        context: getSupplementsManagementEventContext(),
      },
    );
  },
  unableToSkipViewed(props: SupplementsManagementProps) {
    analytics.track(
      `Supplements > Manage > Skip shipment > Unable to skip viewed`,
      getSupplementsManagementEventProperties(props),
      {
        context: getSupplementsManagementEventContext(),
      },
    );
  },
  deterrentTapped(props: SupplementsManagementProps, deterrent: CancellationDeterrents) {
    analytics.track(
      `Supplements > Cancel > ${deterrent} Deterrent Tapped`,
      getSupplementsManagementEventProperties(props),
      {
        context: getSupplementsManagementEventContext(),
      },
    );
  },
  deterrentAccepted(props: SupplementsManagementProps, deterrent: CancellationDeterrents) {
    analytics.track(
      `Supplements > Cancel > ${deterrent} Deterrent Tapped > Confirm`,
      getSupplementsManagementEventProperties(props),
      {
        context: getSupplementsManagementEventContext(),
      },
    );
  },

  deterrentDeclined(props: SupplementsManagementProps, deterrent: CancellationDeterrents) {
    analytics.track(
      `Supplements > Cancel > ${deterrent} Deterrent Tapped > Continue with cancellation`,
      getSupplementsManagementEventProperties(props),
      {
        context: getSupplementsManagementEventContext(),
      },
    );
  },
  deterrentConfirmationGoBack(props: SupplementsManagementProps, deterrent: CancellationDeterrents) {
    analytics.track(
      `Supplements > Cancel > ${deterrent} Deterrent Tapped > Go back`,
      getSupplementsManagementEventProperties(props),
      {
        context: getSupplementsManagementEventContext(),
      },
    );
  },
  updateShipmentAddressTapped(props: SupplementsManagementProps) {
    analytics.track(
      `Supplements > Manage > Update shipment address tapped`,
      getSupplementsManagementEventProperties(props),
      {
        context: getSupplementsManagementEventContext(),
      },
    );
  },
  updateShipmentAddress(props: SupplementsManagementProps) {
    analytics.track(
      `Supplements > Manage > Update shipment address > Update address`,
      getSupplementsManagementEventProperties(props),
      {
        context: getSupplementsManagementEventContext(),
      },
    );
  },
  updateJustThisSubscriptionsAddress(props: SupplementsManagementProps) {
    analytics.track(
      `Supplements > Manage > Update shipment address > Just this subscription`,
      getSupplementsManagementEventProperties(props),
      {
        context: getSupplementsManagementEventContext(),
      },
    );
  },
  updateAllSubscriptionAddresses(props: SupplementsManagementProps) {
    analytics.track(
      `Supplements > Manage > Update shipment address > All subscriptions`,
      getSupplementsManagementEventProperties(props),
      {
        context: getSupplementsManagementEventContext(),
      },
    );
  },
};

interface MonthlyUpgradeProps {
  newSku?: string;
}

function getMonthlyUpgradeEventProperties(props: MonthlyUpgradeProps) {
  return {
    newSku: props.newSku,
  };
}

export const monthlyUpgrade = {
  viewed() {
    analytics.track('Monthly Upgrade Webview Viewed', {});
  },
  upgradePlan(props: MonthlyUpgradeProps) {
    analytics.track('Monthly Upgrade Webview > Upgrade Plan', getMonthlyUpgradeEventProperties(props));
  },
  confirmationModalContinue(props: MonthlyUpgradeProps) {
    analytics.track('Monthly Upgrade Webview > Upgrade Plan > Continue', getMonthlyUpgradeEventProperties(props));
  },
  confirmationModalGoBack(props: MonthlyUpgradeProps) {
    analytics.track('Monthly Upgrade Webview > Upgrade Plan > Back', getMonthlyUpgradeEventProperties(props));
  },
  successfullyUpgraded(props: MonthlyUpgradeProps) {
    analytics.track(
      'Monthly Upgrade Webview > Upgrade Plan > Continue > Success',
      getMonthlyUpgradeEventProperties(props),
    );
  },
};

interface TroubleshootProps {
  petId?: string;
  troubleshootingReason?: gqlTypes.zendeskCancellationReason;
  replacementReason?: string;
  otherReasonText?: string;
  oldSku?: string;
  newSku?: string;
}

function getTroubleshootEventProperties(props: TroubleshootProps) {
  return {
    petId: props.petId,
    troubleshootingReason: props.troubleshootingReason,
    troubleshootingText: props.otherReasonText,
    replacementReason: props.replacementReason,
    oldSku: props.oldSku,
    newSku: props.newSku,
  };
}

// Returns an object containing Segment context fields.
function getTroubleshootEventContext() {
  // Params search string looks like "?platform=android&app_version=3.5.41-internal"
  const paramsString = window.location.search;
  const searchParams = new URLSearchParams(paramsString);
  return {
    os: { name: searchParams.get('platform') },
    app: { version: searchParams.get('app_version') },
  };
}

export const troubleshoot = {
  pageLoad(props: TroubleshootProps, pageName: string) {
    analytics.track(`Troubleshoot > Page Load: ${pageName}`, getTroubleshootEventProperties(props), {
      context: getTroubleshootEventContext(),
    });
  },
  troubleshootStartBack(props: TroubleshootProps) {
    analytics.track(`Troubleshoot > Back`, getTroubleshootEventProperties(props), {
      context: getTroubleshootEventContext(),
    });
  },
  selectedAndSubmittedTroubleshootingReason(props: TroubleshootProps) {
    analytics.track(`Troubleshoot > Selected and submitted reason`, getTroubleshootEventProperties(props), {
      context: getTroubleshootEventContext(),
    });
  },
  tellUsMoreBack(props: TroubleshootProps) {
    analytics.track(`Troubleshoot > Tell Us More > Back`, getTroubleshootEventProperties(props), {
      context: getTroubleshootEventContext(),
    });
  },
  tellUsMoreClose(props: TroubleshootProps) {
    analytics.track(`Troubleshoot > Tell Us More > Close`, getTroubleshootEventProperties(props), {
      context: getTroubleshootEventContext(),
    });
  },
  tellUsMoreContinue(props: TroubleshootProps) {
    analytics.track(`Troubleshoot > Tell Us More > Continue`, getTroubleshootEventProperties(props), {
      context: getTroubleshootEventContext(),
    });
  },
  contactCxBack(props: TroubleshootProps) {
    analytics.track(`Troubleshoot > Contact CX > Back`, getTroubleshootEventProperties(props), {
      context: getTroubleshootEventContext(),
    });
  },
  contactCxClose(props: TroubleshootProps) {
    analytics.track(`Troubleshoot > Contact CX > Close`, getTroubleshootEventProperties(props), {
      context: getTroubleshootEventContext(),
    });
  },
  chatWithCx(props: TroubleshootProps) {
    analytics.track(`Troubleshoot > Contact CX > Chat`, getTroubleshootEventProperties(props), {
      context: getTroubleshootEventContext(),
    });
  },
  callCx(props: TroubleshootProps) {
    analytics.track(`Troubleshoot > Contact CX > Call`, getTroubleshootEventProperties(props), {
      context: getTroubleshootEventContext(),
    });
  },
  submittedOtherTroubleshootingReason(props: TroubleshootProps) {
    analytics.track(`Troubleshoot > Other > Submitted reason`, getTroubleshootEventProperties(props), {
      context: getTroubleshootEventContext(),
    });
  },
  otherReasonBack(props: TroubleshootProps) {
    analytics.track(`Troubleshoot > Other > Back`, getTroubleshootEventProperties(props), {
      context: getTroubleshootEventContext(),
    });
  },
  otherReasonClose(props: TroubleshootProps) {
    analytics.track(`Troubleshoot > Other > Close`, getTroubleshootEventProperties(props), {
      context: getTroubleshootEventContext(),
    });
  },
  troubleshootingTicketConfirmationDoneClicked(props: TroubleshootProps) {
    analytics.track(`Troubleshoot > Other > Done`, getTroubleshootEventProperties(props), {
      context: getTroubleshootEventContext(),
    });
  },
  submittedReplacementTicket(props: TroubleshootProps) {
    analytics.track(
      `Troubleshoot > Physically Damaged S3 > Submit Replacement Request`,
      getTroubleshootEventProperties(props),
      {
        context: getTroubleshootEventContext(),
      },
    );
  },
  replaceCollarBack(props: TroubleshootProps) {
    analytics.track(`Troubleshoot > Physically Damaged S3 > Back`, getTroubleshootEventProperties(props), {
      context: getTroubleshootEventContext(),
    });
  },
  replaceCollarClose(props: TroubleshootProps) {
    analytics.track(`Troubleshoot > Physically Damaged S3 > Close`, getTroubleshootEventProperties(props), {
      context: getTroubleshootEventContext(),
    });
  },
  series2UpgradeClicked(props: TroubleshootProps) {
    analytics.track(`Troubleshoot > Physically Damaged S2 > Upgrade clicked`, getTroubleshootEventProperties(props), {
      context: getTroubleshootEventContext(),
    });
  },
  series2UpgradeBack(props: TroubleshootProps) {
    analytics.track(`Troubleshoot > Physically Damaged S2 > Back`, getTroubleshootEventProperties(props), {
      context: getTroubleshootEventContext(),
    });
  },
  series2UpgradeClose(props: TroubleshootProps) {
    analytics.track(`Troubleshoot > Physically Damaged S2 > Close`, getTroubleshootEventProperties(props), {
      context: getTroubleshootEventContext(),
    });
  },
};

export const errorScreen = {
  pageLoad() {
    analytics.track('Page Load: Error Screen', {});
  },
  callCx() {
    analytics.track('Error Screen > Call', {});
  },
  back() {
    analytics.track('Error Screen > Back', {});
  },
  close() {
    analytics.track('Error Screen > Close', {});
  },
};

export const prepaidActivation = {
  submitCreditCard() {
    analytics.track('Prepaid activation > Submit credit card', {});
  },

  applePayBegin() {
    analytics.track('Prepaid activation > Apple pay begin', {});
  },

  applePaySuccess() {
    analytics.track('Prepaid activation > Apple pay success', {});
  },

  applePayError(message: string) {
    analytics.track('Prepaid activation > Apple pay error', { message });
  },

  applePayCancel() {
    analytics.track('Prepaid activation > Apple pay cancel', {});
  },

  nonSafariApplePayClick() {
    analytics.track('Prepaid activation > Non Safari Apple pay click', {});
  },

  nonSafariApplePayClose() {
    analytics.track('Prepaid activation > Non Safari Apple pay closed', {});
  },

  done() {
    analytics.track('Prepaid activation > Done', {});
  },
};

export const airvetManagement = {
  index: {
    pageLoad(pageName: string) {
      analytics.track(`Page Load: ${pageName}`, {});
    },
  },
  cancellation: {
    surveyBack() {
      analytics.track('Cancel Airvet Membership > Back', {});
    },
    surveyClose() {
      analytics.track('Cancel Airvet Membership > Close', {});
    },
    successClose() {
      analytics.track('Cancel Airvet Membership > Yes, cancel Airvet membership > Close', {});
    },
    cancelConfirmationModalContinue(selectedReason: string) {
      analytics.track(`Cancel Airvet Membership > ${selectedReason} > Yes, cancel Airvet membership`, {});
    },
    cancelConfirmationModalCancel() {
      analytics.track('Cancel Airvet Membership > Nevermind, go back', {});
    },
  },
};

export const renewAndSave = {
  viewed() {
    analytics.track('Renew And Save Webview Viewed', {});
  },
  earlyRenewPlan() {
    analytics.track('Renew And Save Webview > Renew Plan', {});
  },
  confirmationModalContinue() {
    analytics.track('Renew And Save Webview > Renew Plan > Continue', {});
  },
  confirmationModalGoBack() {
    analytics.track('Renew And Save Webview > Renew Plan > Back', {});
  },
  successfullyRenewedAndSaved() {
    analytics.track('Renew And Save Webview > Renew Plan > Continue > Success', {});
  },
};
