import React, { useCallback, useEffect, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import AppPaths from '../../../AppPaths';
import Button from '../../../components/Button';
import Chooser from '../../../components/Chooser';
import { SubscriptionOption } from '../../../components/SubscriptionOptionDetails';
import { useSubscriptionChooserGroups } from '../../../components/SubscriptionOptions/SubscriptionOptions';
import WebViewAppBar from '../../../components/WebViewAppBar';
import { gqlBillingCadenceToBillingCadence } from '../../../lib/subscription';
import { usePurchaseSubscriptionContext } from '../context/PurchaseSubscriptionContext';
import { PurchaseSubscriptionCheckoutState } from '../PurchaseSubscriptionCheckout/PurchaseSubscriptionCheckout';
import styles from './PurchaseSubscriptionsHome.module.scss';
import { cartActions } from '../../../reducers/subscriptionCart';
import { useSubscriptionCartMode } from '../../../lib/cartModes';
import { useDispatch, useSelector } from 'react-redux';
import { isForModuleCartItem } from '../../../lib/cart';

export default function PurchaseSubscriptionHome() {
  const dispatch = useDispatch();
  const history = useHistory();
  const { device, subscriptionOptions } = usePurchaseSubscriptionContext();
  const { cartSelector } = useSubscriptionCartMode();
  const cart = useSelector(cartSelector);

  const applicableSubscriptionProducts = useMemo(
    () =>
      device.purchasableSubscriptionOptions.reduce((applicableProducts: SubscriptionOption[], subscriptionOption) => {
        const devicePreselectedPlanCode = device.preselectedPlanCodeForNewSubscription;
        // Specific to this flow of purchasing a new subscription for a device, if the server tells us to preselect a
        // plan then we want the customer to choose that plan or one with a longer term. The only current use case for
        // preselecting a plan is for the customer's benefit - customers who purchased the collar on Amazon already
        // paid for 6-months of membership and we're automatically applying a discount that only applies to 6-month
        // or longer plans. They would lose out on memberhsip months they've already paid for if they chose a shorter
        // term plan here. For that reason, if we see this preselected plan code we'll hide the plans with a
        // shorter billing term than the preselected one.
        if (devicePreselectedPlanCode) {
          const preselectedSubscriptionOption = subscriptionOptions.find(
            (option) => option.sku === devicePreselectedPlanCode,
          );

          if (
            preselectedSubscriptionOption &&
            subscriptionOption.renewalMonths < preselectedSubscriptionOption.renewalMonths
          ) {
            return applicableProducts;
          }
        }

        applicableProducts.push({
          ...subscriptionOption,
          billingCadence: gqlBillingCadenceToBillingCadence(subscriptionOption.billingCadence),
        });
        return applicableProducts;
      }, []),
    [device.preselectedPlanCodeForNewSubscription, device.purchasableSubscriptionOptions, subscriptionOptions],
  );

  // The server may want us to automatically select a plan for the customer
  const preselectedPlanForNewSubscription = useMemo(() => {
    if (!device.preselectedPlanCodeForNewSubscription) {
      return undefined;
    }

    // Make sure it exists in the applicable list of options
    const subscriptionProduct = applicableSubscriptionProducts.find(
      (option) => option.sku === device.preselectedPlanCodeForNewSubscription,
    );
    if (!subscriptionProduct) {
      return undefined;
    }

    return subscriptionProduct;
  }, [applicableSubscriptionProducts, device.preselectedPlanCodeForNewSubscription]);

  const selectedSubscriptionSku = useMemo(() => {
    const cartItem = Object.values(cart.cartItems)[0];
    if (!cartItem) {
      return undefined;
    }

    return cartItem.lineItem.sku;
  }, [cart.cartItems]);

  const selectedSubscriptionOption = useMemo(() => {
    return applicableSubscriptionProducts.find((option) => option.sku === selectedSubscriptionSku);
  }, [applicableSubscriptionProducts, selectedSubscriptionSku]);

  const chooserGroups = useSubscriptionChooserGroups({
    subscriptionProducts: applicableSubscriptionProducts,
  });

  // Navigate to the checkout page
  const goToCheckout = useCallback(
    (subscriptionOption: SubscriptionOption) => {
      history.push({
        pathname: AppPaths.PurchaseSubscription.Checkout(device.moduleId),
        state: { subscriptionOption } as PurchaseSubscriptionCheckoutState,
      });
    },
    [device.moduleId, history],
  );

  const checkoutDisabled = selectedSubscriptionOption ? false : true;

  // If the server says we should preselect a plan for the customer, we want to go straight to the checkout page
  // for that plan
  useEffect(() => {
    if (!selectedSubscriptionOption && preselectedPlanForNewSubscription) {
      dispatch(
        cartActions.setCartItem({
          moduleId: device.moduleId,
          sku: preselectedPlanForNewSubscription.sku,
        }),
      );

      goToCheckout(preselectedPlanForNewSubscription);
      return;
    }

    // If there's already a cart item, and it's for a different module id we'll just clear the cart
    // so they will need to select a plan again.
    const cartItems = Object.values(cart.cartItems);
    if (!cartItems.length) {
      return;
    }

    const cartItem = cartItems[0];
    if (!isForModuleCartItem(cartItem) || cartItem.forModuleId !== device.moduleId) {
      dispatch(cartActions.resetCart());
    }
  }, [
    cart.cartItems,
    device.moduleId,
    dispatch,
    goToCheckout,
    preselectedPlanForNewSubscription,
    selectedSubscriptionOption,
  ]);

  return (
    <div className={styles.main}>
      <WebViewAppBar />
      <div className={styles.contentWrapper}>
        <h1>Choose your plan</h1>
        <Chooser
          onSelect={(selectedSku) => {
            if (!selectedSku) {
              return;
            }

            dispatch(
              cartActions.setCartItem({
                moduleId: device.moduleId,
                sku: selectedSku,
              }),
            );
          }}
          selectedOption={selectedSubscriptionSku}
          groups={chooserGroups}
        />
        <Button
          className={styles.continueButton}
          disabled={checkoutDisabled}
          onClick={() => {
            if (!selectedSubscriptionOption) {
              return;
            }

            // Explicitly set the cart item again when proceeding to the next step. Otherwise, I think something
            // strange happens when going through the purchase flow for multiple collars and subsequent
            // visits don't change the subscription option (i.e. do not execute the onSelect callback in the
            // Chooser component)
            dispatch(
              cartActions.setCartItem({
                moduleId: device.moduleId,
                sku: selectedSubscriptionOption.sku,
              }),
            );

            goToCheckout(selectedSubscriptionOption);
          }}
        >
          Continue
        </Button>
      </div>
    </div>
  );
}
