import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import classNames from 'classnames';
import { Redirect, useHistory } from 'react-router-dom';

import styles from '../../../../ProductDetails/ProductDetails.module.scss';
import supplementsEcomStyles from './SupplementsEcom.module.scss';
import { DesktopImageGallery } from '../../../../ManageSubscription/Supplements/components/ImageGallery';
import NavBar from '../../../../../components/AppBar/NavBar';
import HideChatWidget from '../../../../../components/ZendeskChat/HideChatWidget';
import FAQv2 from '../../../../Checkout/components/Supplements/ProductInfo/FAQv2';
import {
  AllBenefits,
  getSKUForCartItem,
  PurchaseType,
  Reviews,
  skuIntegerImageMap,
  SKUOption,
  SKUType,
  urlTypeParamToSkuType,
} from '../UpsellV2/UpsellV2';
import AppPaths from '../../../../../AppPaths';
import { getFiGrowthBook } from '../../../../../lib/growthbook';
import CollarAddToBagButton from '../../../../ProductDetails/Series3CollarDetails/components/CollarAddToBagButton';
import { cartActions } from '../../../../../reducers/storeCart';
import { ReactComponent as AddIcon } from '../../../../../assets/images/icons/add.svg';
import { ReactComponent as MinusIcon } from '../../../../../assets/images/icons/minus.svg';

export interface SupplementsEcomProps {
  isEcom: boolean;
}

const SUPPLEMENT_TEXT_MAP: Record<SKUType, { title: string; description: string }> = {
  [SKUType.Formula]: {
    title: '8-in-1 Formula',
    description: 'Packed with essential ingredients for comprehensive support.',
  },
  [SKUType.HipJoint]: {
    title: 'Hip & Joint',
    description: 'Maintains mobility and flexibility with Glucosamine HCl, MSM and more.',
  },
  [SKUType.SkinCoat]: {
    title: 'Skin & Coat',
    description: 'Supports vibrant, glossy coats with Omega-3 Fatty Acids and more.',
  },
  [SKUType.Calming]: {
    title: 'Calming',
    description: 'Promotes relaxation with a blend of CBD, Valerian Root and more.',
  },
};

const EcomPurchaseContainer: React.FC<{
  quantity: number;
  setQuantity: React.Dispatch<React.SetStateAction<number>>;
}> = ({ quantity, setQuantity }) => {
  return (
    <div className={classNames(supplementsEcomStyles.purchaseContainer, supplementsEcomStyles.ecomPurchaseContainer)}>
      <div className={supplementsEcomStyles.frequencyTitle}>Pick your quantity</div>
      <div className={supplementsEcomStyles.frequencyContainer}>
        <button
          className={classNames(supplementsEcomStyles.frequencyMinusButton, {
            [supplementsEcomStyles.frequencyMinusButtonDisabled]: quantity === 1,
          })}
          onClick={() => setQuantity(Math.max(quantity - 1, 1))}
        >
          <MinusIcon />
        </button>
        <div className={supplementsEcomStyles.frequencyValue}>{quantity}</div>
        <button
          className={classNames(supplementsEcomStyles.frequencyPlusButton, {
            [supplementsEcomStyles.frequencyPlusButtonDisabled]: quantity === 100,
          })}
          onClick={() => setQuantity(quantity + 1)}
        >
          <AddIcon />
        </button>
      </div>
    </div>
  );
};

export default function SupplementsEcom() {
  const dispatch = useDispatch();
  const history = useHistory();
  const [currentCarouselImage, setCurrentCarouselImage] = useState<number>(0);
  const [quantity, setQuantity] = useState<number>(1);
  const initialSkuType =
    urlTypeParamToSkuType[new URLSearchParams(window.location.search).get('type') ?? ''] ?? SKUType.Formula;
  const [selectedSKUType, setSelectedSKUType] = useState<SKUType>(initialSkuType);

  const supplementsEcomEnabled = getFiGrowthBook().getFeatureValue<boolean>('ecom-supplements-4-sku', false);
  if (!supplementsEcomEnabled) {
    return <Redirect to={AppPaths.NotFound} />;
  }

  return (
    <>
      <div
        className={classNames(
          styles.productContainer,
          styles.ecomFlowRevamp,
          'ecom-flow-revamp',
          'supplements-ecom-pdp',
          {
            'ecom-flow-revamp-light':
              (selectedSKUType === SKUType.Calming && currentCarouselImage === 0) ||
              (selectedSKUType === SKUType.HipJoint && currentCarouselImage === 2),
          },
        )}
      >
        <div className={styles.galleryContainer}>
          <DesktopImageGallery
            media={[
              {
                type: 'image',
                url: `/product_images/supplements/upsell-v2/supplements-ecom-1-${skuIntegerImageMap[selectedSKUType]}.png`,
                retinaWidth: 1200,
                retinaHeight: 1200,
              },
              {
                type: 'image',
                url: `/product_images/supplements/upsell-v2/supplements-ecom-2-${skuIntegerImageMap[selectedSKUType]}.png`,
                retinaWidth: 1200,
                retinaHeight: 1200,
              },
              {
                type: 'image',
                url: `/product_images/supplements/upsell-v2/supplements-ecom-3-${skuIntegerImageMap[selectedSKUType]}.png`,
                retinaWidth: 1200,
                retinaHeight: 1200,
              },
            ]}
            onChange={(index: number) => setCurrentCarouselImage(index)}
          >
            <NavBar />
          </DesktopImageGallery>
        </div>
        <div className={classNames(styles.productDetails, 'product-details')}>
          <div className={styles.productTitleContainer}>
            <div className={supplementsEcomStyles.productHeader}>FI SUPPLEMENTS</div>
            <div className={supplementsEcomStyles.productTitle}>{SUPPLEMENT_TEXT_MAP[selectedSKUType].title}</div>
            <div className={supplementsEcomStyles.productTitleDescription}>
              {SUPPLEMENT_TEXT_MAP[selectedSKUType].description}
            </div>
            <div className={supplementsEcomStyles.productFooter}>$30/bag</div>
            <br />
            <div className={supplementsEcomStyles.skuOptionSelector}>
              {Object.values(SKUType).map((skuType) => (
                <SKUOption
                  key={skuType}
                  skuType={skuType}
                  selectedSKUType={selectedSKUType}
                  onSelect={setSelectedSKUType}
                />
              ))}
            </div>
            <div className={supplementsEcomStyles.divider} />
            <EcomPurchaseContainer quantity={quantity} setQuantity={setQuantity} />
            <div className={supplementsEcomStyles.divider} />
            <div className={supplementsEcomStyles.addToBagContainer}>
              <CollarAddToBagButton
                descriptionText={null}
                isVisibleOverride={true}
                onAddToBag={() => {
                  dispatch(
                    cartActions.addSingleLineCartItem({
                      sku: getSKUForCartItem({
                        skuType: selectedSKUType,
                        includeFreeTrial: false,
                        purchaseType: PurchaseType.OneTime,
                      }),
                      quantity: quantity,
                    }),
                  );
                  history.push(AppPaths.Bag);
                  return true;
                }}
              />
            </div>
          </div>
        </div>
      </div>
      <div className={supplementsEcomStyles.benefitsContainer}>
        <AllBenefits skuType={selectedSKUType} />
      </div>
      <div className={supplementsEcomStyles.divider} />
      <div className={supplementsEcomStyles.reviewsContainer}>
        <Reviews />
      </div>
      <div className={supplementsEcomStyles.divider} />
      <div className={classNames(supplementsEcomStyles.faqContainer, 'supplements-ecom-faq')}>
        <FAQv2 />
      </div>
      <HideChatWidget />
    </>
  );
}
