import React, { useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import { ReactComponent as CheckIcon } from '../../../../../assets/images/icons/check.svg';
import Button from '../../../../../components/Button';
import WebViewAppBar from '../../../../../components/WebViewAppBar/WebViewAppBar';
import HideChatWidget from '../../../../../components/ZendeskChat/HideChatWidget';
import * as events from '../../../../../lib/analytics/events';
import styles from './CancelConfirmation.module.scss';
import { DateTime } from 'luxon';

interface CancelConfirmationState {
  nextBillDate: string;
}

export default function CancelConfirmation() {
  const location = useLocation<CancelConfirmationState>();
  const nextBillDate = location.state?.nextBillDate ?? null;
  const onClose = useCallback(() => {
    events.airvetManagement.cancellation.successClose();
    window.open('fi://closeappview');
  }, []);

  return (
    <>
      <div className={styles.confirmationContainer}>
        <WebViewAppBar />
        <div className={styles.section}>
          <div className={styles.checkIcon}>
            <CheckIcon width="64px" height="64px" />
          </div>
          <h2>Airvet membership canceled</h2>
          <p>
            You will no longer be charged for Airvet access beginning on{' '}
            {DateTime.fromISO(nextBillDate).toFormat('LLLL d, yyyy')}.
          </p>
        </div>
        <div className={styles.actions}>
          <Button onClick={onClose}>Close</Button>
          <div className={styles.buttonSpacer} />
        </div>
        <HideChatWidget />
      </div>
    </>
  );
}
