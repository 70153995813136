import React, { useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import styles from './MonthlyUpgradeSelection.module.scss';
import { usePurchaseSubscriptionContext } from '../PurchaseSubscription/context/PurchaseSubscriptionContext';
import Button from '../../components/Button';
import { SubscriptionOption } from '../../components/SubscriptionOptionDetails/SubscriptionOptionDetails';
import { useSubscriptionCartMode } from '../../lib/cartModes';
import { gqlBillingCadenceToBillingCadence } from '../../lib/subscription';
import { cartActions } from '../../reducers/subscriptionCart';
import { useSubscriptionChooserGroups } from '../../components/SubscriptionOptions/SubscriptionOptions';
import Chooser from '../../components/Chooser';
import AppPaths from '../../AppPaths';
import { centsToDollars, centsToDollarsNumber, getLoadingOrErrorElement, getPriceForRateUnit } from '../../lib/util';
import classNames from 'classnames';
import { BillingCadence, gqlTypes } from '../../types';
import MonthlyUpgradeConfirmationModal from './MonthlyUpgradeConfirmationModal';
import { useMutation } from '@apollo/client';
import { purchaseMonthlyUpgradeMutation } from '../../graphql-operations/MonthlyUpgrade.graphql';
import { PurchaseSubscriptionCompleteState } from '../PurchaseSubscription/PurchaseSubscriptionComplete/PurchaseSubscriptionComplete';
import { DateTime } from 'luxon';
import ErrorMessage, { useError } from '../../components/ErrorMessage';
import { monthlyUpgrade as events } from '../../lib/analytics/events';
import { getFiGrowthBook } from '../../lib/growthbook';
import { Image } from '../../components/Image';
import { applyCouponToCart, isValidCodeResult } from '../../lib/promoCode';
import { MonthlyUpgradeCompleteState } from './MonthlyUpgradeComplete';

import CollarHero from '../../assets/images/collar_hero_blue_3.png';
import Check from '../../assets/images/icons/check_green.svg';

const SIX_MONTH_PREPAID_PLAN_CODE = 'sub-monthly-6m-001';
const ONE_YEAR_PREPAID_PLAN_CODE = 'sub-monthly-12m-001';
const TWO_YEAR_PREPAID_PLAN_CODE = 'sub-monthly-24m-001';
const SIMPLIFIED_SIXTH_MONTH_PLAN_CODE = 'sub-monthly-6m-006';

function getSubscriptionDescription(subscription: SubscriptionOption): string {
  if (subscription.sku === SIX_MONTH_PREPAID_PLAN_CODE) {
    return '6 Month Membership';
  } else if (subscription.sku === ONE_YEAR_PREPAID_PLAN_CODE) {
    return '1 Year Membership';
  } else if (subscription.sku === TWO_YEAR_PREPAID_PLAN_CODE) {
    return '2 Year Membership';
  } else if (subscription.sku === SIMPLIFIED_SIXTH_MONTH_PLAN_CODE) {
    return '6 month';
  } else {
    return subscription.name;
  }
}

function getNumFreeMonthByPlanCode(planCode: string): number {
  const planToFreeMonthsMap: any = {
    [SIX_MONTH_PREPAID_PLAN_CODE]: 1,
    [ONE_YEAR_PREPAID_PLAN_CODE]: 2,
    [TWO_YEAR_PREPAID_PLAN_CODE]: 3,
  };

  return planToFreeMonthsMap[planCode] ?? 0;
}

export default function MonthlyUpgradeSelection() {
  const dispatch = useDispatch();
  const history = useHistory();
  const { device, couponCode } = usePurchaseSubscriptionContext();
  const { cartSelector } = useSubscriptionCartMode();
  const cart = useSelector(cartSelector);
  const { error, errorID, setError } = useError();
  const [upgradeClicked, setUpgradeClicked] = useState(false);
  events.viewed();

  const applicableSubscriptionProducts = device.purchasableSubscriptionOptions.reduce(
    (applicableProducts: SubscriptionOption[], subscriptionOption) => {
      applicableProducts.push({
        ...subscriptionOption,
        billingCadence: gqlBillingCadenceToBillingCadence(subscriptionOption.billingCadence),
      });
      return applicableProducts;
    },
    [],
  );

  const selectedSubscriptionSku = useMemo(() => {
    const cartItem = Object.values(cart.cartItems)[0];
    if (!cartItem) {
      return undefined;
    }

    return cartItem.lineItem.sku;
  }, [cart.cartItems]);

  const selectedSubscriptionOption = useMemo(() => {
    return applicableSubscriptionProducts.find((option) => option.sku === selectedSubscriptionSku);
  }, [applicableSubscriptionProducts, selectedSubscriptionSku]);

  const appliedCouponCode = useMemo(() => {
    return cart.couponCode;
  }, [cart.couponCode]);

  const chooserGroups = useSubscriptionChooserGroups({
    subscriptionProducts: applicableSubscriptionProducts,
    monthlyUpgrade: true,
    showSubscriptionLengthDiscount: true,
  });

  const simplifiedOffer = getFiGrowthBook().getFeatureValue<boolean>('in-app-mtm-6-month-upsell', false);
  const currentMTMPriceCents =
    device.subscription!.subscriptionOption.priceInCents * (selectedSubscriptionOption?.renewalMonths ?? 1);
  const chargedTodayCents =
    selectedSubscriptionOption?.planSwitchAmountChargedInCents ?? selectedSubscriptionOption?.priceInCents ?? 0;
  const creditAppliedCents = selectedSubscriptionOption?.planSwitchCreditAppliedInCents ?? 0;
  const monthlyCostCents = chargedTodayCents / (selectedSubscriptionOption?.renewalMonths ?? 1);
  const hasDiscount = chargedTodayCents < currentMTMPriceCents;

  const [purchaseMonthlyUpgrade, purchaseMonthlyUpgradeState] = useMutation<
    gqlTypes.ECOMMERCE_purchaseMonthlyUpgrade,
    gqlTypes.ECOMMERCE_purchaseMonthlyUpgradeVariables
  >(purchaseMonthlyUpgradeMutation, {
    onCompleted: (data) => {
      const state: PurchaseSubscriptionCompleteState = {
        subscriptionProduct: selectedSubscriptionOption,
        showAppBar: false,
      };
      const simpleState: MonthlyUpgradeCompleteState = {
        chargedTodayCents,
        originalPeriodCostCents: currentMTMPriceCents,
        creditAppliedCents,
        subscriptionDescription: selectedPlanDescription,
        subscriptionProduct: selectedSubscriptionOption,
      };
      events.successfullyUpgraded({ newSku: selectedSubscriptionSku! });
      if (simplifiedOffer) {
        history.push({
          pathname: AppPaths.MonthlyUpgrade.CompleteV2(device.moduleId),
          state: simpleState,
          search: window.location.search,
        });
      } else {
        history.push({
          pathname: AppPaths.MonthlyUpgrade.Complete(device.moduleId),
          state,
          search: window.location.search,
        });
      }
    },
    onError(err) {
      setError(err.message);
    },
  });

  const loadingOrErrorElement = getLoadingOrErrorElement(purchaseMonthlyUpgradeState.loading, null);
  if (loadingOrErrorElement) {
    return loadingOrErrorElement;
  }

  const upgradeMembership = () => {
    purchaseMonthlyUpgrade({
      variables: {
        input: {
          moduleId: device.moduleId,
          sku: selectedSubscriptionSku!,
          couponCodes: appliedCouponCode ? [appliedCouponCode] : null,
        },
      },
    });
  };

  const checkoutDisabled = selectedSubscriptionOption ? false : true;
  const selectedPlanDescription = selectedSubscriptionOption && getSubscriptionDescription(selectedSubscriptionOption);

  const { displayRate } = getPriceForRateUnit(
    {
      billingCadence: BillingCadence.Month,
      displayedRate: device.subscription!.subscriptionOption.displayedRate,
      priceInCents: device.subscription!.subscriptionOption.priceInCents,
      renewalMonths: device.subscription!.subscriptionOption.renewalMonths,
    },
    'week',
  );

  const { displayRate: displayRateMonth } = getPriceForRateUnit(
    {
      billingCadence: BillingCadence.Month,
      displayedRate: device.subscription!.subscriptionOption.displayedRate,
      priceInCents: device.subscription!.subscriptionOption.priceInCents,
      renewalMonths: device.subscription!.subscriptionOption.renewalMonths,
    },
    'month',
  );

  /**
   * If user follows through with upgrade, we'll either:
   * a) apply a coupon code and switch now, meaning the renewal date is after one term of the new plan, or
   * b) grant the free months by extending the current monthly plan so renewal date will depend on how many
   * free months they get, which is based on which plan they select.
   */
  const previewRenewalDate = selectedSubscriptionSku
    ? couponCode
      ? DateTime.now().plus({ months: selectedSubscriptionOption?.renewalMonths })
      : DateTime.fromISO(device.subscription?.currentTermEndsAt).plus({
          months: getNumFreeMonthByPlanCode(selectedSubscriptionSku),
        })
    : device.subscription?.currentTermEndsAt;

  if (simplifiedOffer) {
    const targetSubscription = applicableSubscriptionProducts.find((s) => s.sku === SIMPLIFIED_SIXTH_MONTH_PLAN_CODE);

    if (!selectedSubscriptionSku && targetSubscription) {
      dispatch(
        cartActions.setCartItem({
          moduleId: device.moduleId,
          sku: targetSubscription.sku,
        }),
      );
      if (couponCode) {
        applyCouponToCart(cart, couponCode).then((couponResult) => {
          if (isValidCodeResult(couponResult)) {
            dispatch(cartActions.addCoupon(couponCode));
          }
        });
      }
    }

    return (
      <>
        <div className={styles.simpleFullWidthWrapper}>
          <div className={styles.simpleContainer}>
            <div className={styles.specialOfferChip}>
              <p>SPECIAL OFFER</p>
            </div>
            <div className={styles.simpleHeaderContainer}>
              <h1 className={styles.simpleHeader}>Switch and Save</h1>
              <p className={styles.simpleSubheader}>
                Switch from your {displayRateMonth}/mo plan and get your next{' '}
                {selectedSubscriptionOption?.renewalMonths} months for only ${centsToDollars(monthlyCostCents)}/mo
              </p>
            </div>
            <div className={styles.offerContainer}>
              <div className={styles.offerDetailsContainer}>
                <p className={styles.upsellSubscriptionTermText}>{selectedPlanDescription}</p>
                <div className={styles.pricesContainer}>
                  <p className={styles.upsellSubscriptionPriceText}>
                    ${Math.ceil(centsToDollarsNumber(chargedTodayCents))}
                  </p>
                  {hasDiscount && (
                    <p className={styles.currentSubscriptionPriceText}>${centsToDollarsNumber(currentMTMPriceCents)}</p>
                  )}
                </div>
                {hasDiscount && (
                  <p className={styles.savingsText}>
                    You'll save ${Math.floor(centsToDollarsNumber(currentMTMPriceCents - chargedTodayCents))} over the
                    next {selectedSubscriptionOption?.renewalMonths} months
                    {creditAppliedCents > 0 && (
                      <span>
                        {' '}
                        with a rollover credit of ${Math.round(centsToDollarsNumber(creditAppliedCents))} from recent
                        bill
                      </span>
                    )}
                    .
                  </p>
                )}
                <Image className={styles.checkmark} image={{ type: 'image', url: Check }} />
              </div>
            </div>
            <div className={styles.collarContainer}>
              <Image className={styles.collarImage} image={{ type: 'image', url: CollarHero }} />
            </div>
            <MonthlyUpgradeConfirmationModal
              onContinue={() => upgradeMembership()}
              subscriptionOption={selectedSubscriptionOption}
              previewRenewalDate={previewRenewalDate}
              open={upgradeClicked && !error}
              upgradeNow={couponCode ? true : false}
              trigger={
                <Button
                  className={styles.simpleButton}
                  disabled={checkoutDisabled}
                  onClick={() => {
                    events.upgradePlan({ newSku: selectedSubscriptionSku });
                    setUpgradeClicked(true);
                  }}
                >
                  Claim Offer
                </Button>
              }
            />
          </div>
        </div>
      </>
    );
  }

  return (
    <>
      <div className={styles.fullWidthWrapper}>
        <div className={styles.container}>
          <h1 className={styles.mainHeader}>Upgrade Your Membership</h1>
          <div className={styles.sectionHeaderContainer}>
            <h2 className={styles.sectionHeader}>Current plan</h2>
          </div>
          <div className={styles.currentPlanContainer}>
            <div>
              <div className={styles.currentPlanUnit}>MONTH TO MONTH</div>
              <div className={styles.currentPlanRate}>{displayRate}/week</div>
            </div>
          </div>
          <div className={styles.sectionHeaderContainer}>
            <h2 className={styles.sectionHeader}>Select a new plan</h2>
          </div>
          {applicableSubscriptionProducts.length === 0 ? (
            <p>No plans available. Please contact support@tryfi.com if you think this is an error.</p>
          ) : (
            <>
              {' '}
              <Chooser
                onSelect={(selectedSku) => {
                  if (!selectedSku) {
                    return;
                  }

                  dispatch(
                    cartActions.setCartItem({
                      moduleId: device.moduleId,
                      sku: selectedSku,
                    }),
                  );
                }}
                selectedOption={selectedSubscriptionSku}
                groups={chooserGroups}
              />
              <div className={classNames(styles.stickyCTA, styles.productAction)}>
                {selectedPlanDescription && (
                  <div className={styles.selectedPlanDescription}>{selectedPlanDescription}</div>
                )}
                <MonthlyUpgradeConfirmationModal
                  onContinue={() => upgradeMembership()}
                  subscriptionOption={selectedSubscriptionOption}
                  previewRenewalDate={previewRenewalDate}
                  open={upgradeClicked && !error} // Close popup if there's an error when user tries to upgrade
                  trigger={
                    <Button
                      className={styles.button}
                      disabled={checkoutDisabled}
                      onClick={() => {
                        events.upgradePlan({ newSku: selectedSubscriptionSku });
                        setUpgradeClicked(true);
                      }}
                    >
                      {selectedSubscriptionOption ? 'Upgrade Plan' : 'Pick your membership'}
                    </Button>
                  }
                />
                {error && <ErrorMessage errors={[error]} errorID={errorID} />}
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
}
