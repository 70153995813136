import React from 'react';

import { centsToDollarsNumber } from '../../lib/util';
import ConfirmModal from '../../components/modals/ConfirmModal';
import styles from '../ManageSubscription/styles/SubscriptionContainer.module.scss';

interface IRenewAndSaveConfirmationModalProps {
  earlyRenewalSuggestedTermEnd: string;
  earlyRenewalChargeAmountCents: number;
  termLengthMonths: number;

  // IConfirmModalProps
  trigger: JSX.Element;
  onAccept?: () => void;
  onDecline?: () => void;
}

export default function RenewAndSaveConfirmationModal({
  earlyRenewalSuggestedTermEnd,
  earlyRenewalChargeAmountCents,
  termLengthMonths,
  trigger,
  onAccept = () => {},
  onDecline = () => {},
}: IRenewAndSaveConfirmationModalProps) {
  return (
    <ConfirmModal
      trigger={trigger}
      body={
        <>
          <h3>Confirm renewal</h3>
          <br />
          <p>
            You will be charged{' '}
            <span className={styles.emphasize}>${centsToDollarsNumber(earlyRenewalChargeAmountCents)} </span>
            now and your membership will extend another {termLengthMonths} months to{' '}
            <span className={styles.emphasize}>{earlyRenewalSuggestedTermEnd}.</span>
          </p>
        </>
      }
      acceptText={'Confirm'}
      declineText={'Go back'}
      onAccept={() => {
        onAccept();
      }}
      onDecline={() => {
        onDecline();
      }}
    />
  );
}
