import { gql } from '@apollo/client';

export const previewRenewAndSaveQuery = gql`
  query ECOMMERCE_previewRenewAndSave($input: RenewAndSaveInput!) {
    previewRenewAndSave(input: $input) {
      subtotalInCents
      taxInCents
      totalInCents
    }
  }
`;

export const purchaseRenewAndSaveMutation = gql`
  mutation ECOMMERCE_purchaseRenewAndSave($input: RenewAndSaveInput!) {
    purchaseRenewAndSave(input: $input) {
      chargeInvoice {
        number
      }
      giftCardInvoices {
        number
      }
      isReturningCustomer
    }
  }
`;
