import { combineReducers } from '@reduxjs/toolkit';
import { inPersonEventInitialState, inPersonEventReducer } from './inPersonEvent';
import { affiliateDataInitialState, affiliateDataReducer } from './affiliateData';
import { configInitialState, configReducer } from './config';
import { sessionInitialState, sessionReducer } from './session';
import * as types from '../types';
import { series3UpgradeShopInitialState, series3UpgradeShopReducer } from './series3UpgradeShop';
import { storeShopInitialState, storeShopReducer } from './storeShop';
import { subscriptionShopInitialState, subscriptionShopReducer } from './subscriptionShop';
import { tenancyInitialState, tenancyReducer } from './tenancy';

export const initialAppState: types.AppState = {
  ...affiliateDataInitialState,
  ...configInitialState,
  ...series3UpgradeShopInitialState,
  ...sessionInitialState,
  ...storeShopInitialState,
  ...subscriptionShopInitialState,
  ...inPersonEventInitialState,
  ...tenancyInitialState,
};

const rootReducer = combineReducers<types.AppState>({
  affiliateData: affiliateDataReducer,
  config: configReducer,
  subscriptionShop: subscriptionShopReducer,
  series3UpgradeShop: series3UpgradeShopReducer,
  session: sessionReducer,
  storeShop: storeShopReducer,
  inPersonEvent: inPersonEventReducer,
  tenancy: tenancyReducer,
});

export default rootReducer;
