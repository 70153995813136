import { ConnectedPromotionBanner, usePromotion } from '@barkinglabs/promotions-react';
import { PromotionConfigurationKind } from '@barkinglabs/promotions-types';
import { useGrowthBook } from '@growthbook/growthbook-react';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useShowPromotions } from '../contexts/ShowPromotionsContext';
import { getFiGrowthBook, PromotionBannerVariant } from '../lib/growthbook';
import { applyCouponToCart } from '../lib/promoCode';
import setCouponCookie from '../lib/setCouponCookie';
import { getNanoReferralPetNameCookie } from '../lib/util/nanoReferralPetNameCookie';
import { cartActions } from '../reducers/storeCart';
import { AppState } from '../types';
import CouponBanner from './CouponBanner/CouponBanner';
import getCouponCookie from '../lib/getCouponCookie';
import NanoBanner, { showNanoVariant } from './NanoBanner/NanoBanner';
import { webApiUrl } from '../lib/fi-api/apiUtils';
import { useLocation } from 'react-router-dom';
import { inCartS3CollarCount } from './ItemsInCart/CouponLineItemV2';

interface ShopPromotionBannerProps {
  showBannerOnApply?: boolean;
  /**
   * Move the ZD chat widget up. Should be specified if we're displaying
   * this promotion as a banner at the bottom of the screen.
   */
  className?: string;
  // Callback function which is called when the banner is shown or hidden.
  bannerShownCallback?: (shown: boolean) => void;
}

function useShopPromotionBannerDetails(forcedVariant?: number) {
  const showPromotions = useShowPromotions();
  const { variant } = usePromotion({ forcedVariant, overrideApiHost: webApiUrl });
  const cart = useSelector((state: AppState) => state.storeShop.cart);
  const showPromoBannerImprovements = useSelector(
    (state: AppState) => !!state.config.siteConfig.enableEcommPromoBannerImprovements,
  );

  const couponCode: string | undefined =
    (variant?.configuration.kind === PromotionConfigurationKind.SIMPLE && variant.configuration.couponCode) ||
    undefined;

  /**
   * If improved promo banner feature flag is on, show promo code when:
   * 1. Showing promotion is enabled
   * 2. We have a promotion variant
   *
   * If improved promo banner feature flag is off, show promo code when:
   * 1. Showing promotion is enabled
   * 2. The cart doesn't already have a coupon code
   * 3. We have a promotion variant
   */
  const showPromotion = showPromoBannerImprovements
    ? showPromotions && !!variant
    : showPromotions && !cart.couponCode && !!variant;

  return {
    couponCode,
    showPromotion,
  };
}

function useAutoApplyCoupon(onClick: VoidFunction) {
  const cart = useSelector((state: AppState) => state.storeShop.cart);
  const growthBookEnabled = !!useGrowthBook();
  const { pathname } = useLocation();
  // auto-apply promo code
  useEffect(() => {
    const shouldAutoApplyBannerCode = growthBookEnabled
      ? getFiGrowthBook().getFeatureValue<boolean>('ecom-auto-apply-promo-code', false)
      : false;
    const cartItems = Object.values(cart.cartItems);
    const totalCollarCount = inCartS3CollarCount(cartItems);
    const nonPrepaidCount =
      inCartS3CollarCount(cartItems, 'sub-monthly-1m-001') + inCartS3CollarCount(cartItems, 'sub-monthly-3m-001');
    // From the cart, we want to auto-apply the promo code by simulating a click event on the banner.
    if (
      shouldAutoApplyBannerCode &&
      pathname.startsWith('/bag') &&
      totalCollarCount > 0 &&
      totalCollarCount - nonPrepaidCount > 0
    ) {
      onClick && onClick();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);
}

export default function ShopPromotionBanner({ showBannerOnApply, bannerShownCallback }: ShopPromotionBannerProps) {
  // useGrowthBook returns undefined if we're not wrapped in a GrowthBookProvider. We only use the GrowthBookProvider in
  // the main store flow so in-app webviews won't have this.
  const growthBookEnabled = !!useGrowthBook();

  const dispatch = useDispatch();
  const cart = useSelector((state: AppState) => state.storeShop.cart);
  const showPromoBannerImprovements = useSelector(
    (state: AppState) => !!state.config.siteConfig.enableEcommPromoBannerImprovements,
  );

  const [error, setError] = useState<string>();
  const [appliedBannerState, setAppliedBannerState] = useState<'never' | 'shown' | 'dismissed'>('never');

  const variantCouponCodeDiscountEnabled = growthBookEnabled
    ? getFiGrowthBook().getFeatureValue<PromotionBannerVariant>(
        'ecom-variant-coupon-code-discount-multivalue',
        PromotionBannerVariant.DEFAULT,
      ) === PromotionBannerVariant.EXPERIMENT
    : false;

  const promoBannerVariant = variantCouponCodeDiscountEnabled ? 1 : 0;
  const couponCookie = getCouponCookie();

  let { couponCode, showPromotion } = useShopPromotionBannerDetails(promoBannerVariant);
  const forceNanoBanner = showNanoVariant() || !!getNanoReferralPetNameCookie();
  showPromotion = showPromotion || forceNanoBanner;
  couponCode = forceNanoBanner ? 'Nano20' : couponCode;

  const doApply = async (theCouponCode: string) => {
    const result = await applyCouponToCart(cart, theCouponCode);
    if (result.kind === 'success') {
      dispatch(cartActions.addCoupon(result.value.code));
      setCouponCookie(result.value.code);
      if (showBannerOnApply) {
        setAppliedBannerState('shown');
      }
      setError(undefined);
      return true;
    } else {
      setError(result.err.message);
      return false;
    }
  };

  useEffect(() => {
    const applyCoupon = async () => {
      if (couponCookie) {
        const successfullyAppliedCode = await doApply(couponCookie.code);
        if (!successfullyAppliedCode && couponCode) {
          doApply(couponCode);
        }
      }
    };
    applyCoupon();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [couponCode]); // Dependency array is non-exhaustive so useEffect runs only on initial page load.

  const onClick = () => {
    if (couponCode) {
      doApply(couponCode);
    }
  };

  useAutoApplyCoupon(onClick);

  // If user has entered a referral code, AppBar component returns a ReferralBanner,
  // so no additional banner should be returned from this component.
  if (cart.referralCode) {
    if (bannerShownCallback) {
      bannerShownCallback(false);
    }
    return null;
  }

  if (error && appliedBannerState !== 'dismissed') {
    if (bannerShownCallback) {
      bannerShownCallback(true);
    }
    return (
      <CouponBanner
        bannerText={`Coupon Error: ${error}`}
        onDismiss={() => {
          setAppliedBannerState('dismissed');
        }}
        error={true}
      />
    );
  }

  if (bannerShownCallback) {
    bannerShownCallback(true);
  }

  // If cart.couponCode (the coupon code in user's cart) doesn't match couponCode (coupon on promotional banner),
  // continue to surface the promotion banner.
  if (showPromotion && (!cart.couponCode || cart.couponCode?.toLowerCase() !== couponCode?.toLowerCase())) {
    if (forceNanoBanner) {
      return <NanoBanner cart={cart} promoCodeApplied={false} onClick={onClick} />;
    }
    return <ConnectedPromotionBanner forceVariant={promoBannerVariant} onClick={onClick} />;
  }

  if (forceNanoBanner) {
    return <NanoBanner cart={cart} promoCodeApplied={true} onClick={onClick} />;
  }

  return showPromoBannerImprovements ? (
    <ConnectedPromotionBanner
      apiHost={webApiUrl}
      forceVariant={promoBannerVariant}
      onClick={onClick}
      title={`Promo code applied: ${couponCode}`}
    />
  ) : (
    <CouponBanner
      bannerText="Promo applied!"
      onDismiss={() => {
        setAppliedBannerState('dismissed');
      }}
    />
  );
}
