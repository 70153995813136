import React from 'react';
import { Switch } from 'react-router';
import AppPaths from '../../../../AppPaths';
import SentryRoute from '../../../../SentryRoute';
import CancelSurvey from './Cancellation/CancelSurvey';
import CancelConfirmation from './Cancellation/CancelConfirmation';

export default function Airvet() {
  return (
    <Switch>
      <SentryRoute exact path={AppPaths.Airvet.Cancel} component={CancelSurvey} />
      <SentryRoute exact path={AppPaths.Airvet.CancelConfirmation} component={CancelConfirmation} />
    </Switch>
  );
}
