import React, { useState } from 'react';

import Popup from 'reactjs-popup';
import styles from './DeliveryFrequencyModal.module.scss';
import { ReactComponent as CloseIcon } from '../../../../../assets/images/icons/close.svg';
import { ReactComponent as AddIcon } from '../../../../../assets/images/icons/add.svg';
import { ReactComponent as MinusIcon } from '../../../../../assets/images/icons/minus.svg';
import classNames from 'classnames';

interface IDeliveryFrequencyModalProps {
  onContinue: (frequency: number) => void;
  trigger: React.ReactElement;
}

function getRecommendedFrequencyInWeeksForWeight(weight: number): number {
  if (weight >= 75) return 4;
  if (weight >= 25) return 6;
  return 12;
}

function getDogSizeForWeight(weight: number): 'small' | 'medium' | 'large' {
  if (weight >= 75) return 'large';
  if (weight >= 25) return 'medium';
  return 'small';
}

export function WeightFrequencySelector({
  onContinue,
  close,
}: {
  onContinue: (frequency: number) => void;
  close: () => void;
}) {
  const [currentWeight, setCurrentWeight] = useState<number>(40);
  const recommendedFrequency = getRecommendedFrequencyInWeeksForWeight(currentWeight);

  return (
    <div className={styles.body}>
      <div className={styles.subtitle}>How much does your dog weigh?</div>
      <div className={styles.weightContainer}>
        <button
          className={classNames(styles.weightMinusButton, {
            [styles.weightMinusButtonDisabled]: currentWeight === 1,
          })}
          onClick={() => setCurrentWeight(Math.max(currentWeight - 1, 1))}
        >
          <MinusIcon />
        </button>
        <div className={styles.weightValue}>
          <input
            type="number"
            inputMode="numeric"
            pattern="[0-9]*"
            value={currentWeight}
            min="5"
            max="200"
            step="1"
            onChange={(e) => setCurrentWeight(parseInt(e.currentTarget.value))}
          ></input>{' '}
          <span className={styles.currentWeightValueUnit}>{currentWeight === 1 ? 'lb.' : 'lbs.'}</span>
        </div>
        <button
          className={classNames(styles.weightPlusButton, {
            [styles.weightPlusButtonDisabled]: currentWeight === 200,
          })}
          onClick={() => setCurrentWeight(Math.min(currentWeight + 1, 200))}
        >
          <AddIcon />
        </button>
      </div>
      <div className={classNames(styles.frequencyContainer, 'frequency-container')}>
        <div className={styles.frequencyRecommendation}>
          We recommend delivery every{' '}
          <span className={styles.frequencyRecommendationValue}>{recommendedFrequency} weeks.</span>
        </div>
        <div className={styles.frequencyRecommendationDetails}>
          This frequency is best for {getDogSizeForWeight(currentWeight)} dogs.
        </div>
        <button
          className={styles.frequencyUpdateButton}
          onClick={() => {
            onContinue(recommendedFrequency);
            close();
          }}
        >
          Update
        </button>
      </div>
    </div>
  );
}

export default function DeliveryFrequencyModal({ onContinue, trigger }: IDeliveryFrequencyModalProps) {
  return (
    <Popup className="s3-popup" trigger={trigger} modal>
      {(close: () => void) => (
        <div className={styles.container}>
          <div className={styles.header}>
            <div className={styles.title}>Delivery frequency</div>
            <div className={styles.closeIcon}>
              <CloseIcon onClick={() => close()} />
            </div>
          </div>
          <WeightFrequencySelector onContinue={onContinue} close={close} />
        </div>
      )}
    </Popup>
  );
}
