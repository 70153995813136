// https://developers.applovin.com/zh/audience-plus/events-and-objects

import { cartItemToSegmentProducts, ProductEventProps } from '../lib/analytics/events';
import { centsToDollarsNumber } from '../lib/util';
import { CartItem, CartPricing } from './carts';
export interface AxonTrack {
  (eventType: 'track', eventName: 'page_view'): void;
  (
    eventType: 'track',
    eventName: 'view_item',
    { currency, items, value }: { currency: string; items: AxonItem[]; value?: number },
  ): void;
  (
    eventType: 'track',
    eventName: 'add_to_cart',
    { currency, items, value }: { currency: string; items: AxonItem[]; value?: number },
  ): void;
  (
    eventType: 'track',
    eventName: 'begin_checkout',
    { currency, items, value }: { currency: string; items: AxonItem[]; value: number },
  ): void;
  (
    eventType: 'track',
    eventName: 'purchase',
    {
      currency,
      items,
      transaction_id,
      value,
      shipping,
      tax,
      user_id,
    }: {
      currency: string;
      items: AxonItem[];
      transaction_id: string;
      value: number;
      shipping?: number;
      tax?: number;
      user_id?: string;
    },
  ): void;
  (
    eventType: 'track',
    eventName: 'add_payment_info',
    {
      currency,
      items,
      payment_type,
      value,
    }: { currency: string; items?: AxonItem[]; payment_type?: AxonPaymentType; value?: number },
  ): void;
  (
    eventType: 'track',
    eventName: 'generate_lead',
    { currency, user_id, value }: { currency?: string; user_id?: string; value?: number },
  ): void;
  (eventType: 'track', eventName: 'login', { user_id }: { user_id?: string }): void;
  (eventType: 'track', eventName: 'sign_up', { method, user_id }: { method?: string; user_id?: string }): void;
  (
    eventType: 'track',
    eventName: 'search',
    { search_term, results }: { search_term: string; results?: AxonItem[] },
  ): void;
  (
    eventType: 'track',
    eventName: 'subscribe',
    { currency, user_id, value }: { currency?: string; user_id?: string; value?: number },
  ): void;
  (
    eventType: 'track',
    eventName: 'remove_from_cart',
    { currency, items, value }: { currency: string; items: AxonItem[]; value?: number },
  ): void;
}

interface AxonItem {
  item_variant_id?: string;
  item_id: string;
  item_name: string;
  price: number;
  quantity: number;
  image_url?: string;
  item_category_id?: number; // https://developers.applovin.com/zh/audience-plus/events-and-objects#category-ids
  affiliation?: string;
  discount?: number;
  item_brand?: string;
}

type AxonPaymentType = 'credit_card' | 'deferred' | 'redeemable' | 'payment_on_delivery' | 'wallet' | 'other';

export function productEventPropsToAxonItem(props: ProductEventProps): AxonItem {
  return {
    item_id: props.sku,
    item_name: props.productName,
    price: centsToDollarsNumber(props.priceInCents), // Axon expects a price in dollars, not cents
    quantity: props.quantity ?? 1,
  };
}

export function cartItemToAxonItems(cartItem: CartItem): AxonItem[] {
  const axonItems: AxonItem[] = [];

  const segmentProducts = cartItemToSegmentProducts(cartItem);

  for (const segmentProduct of segmentProducts) {
    axonItems.push({
      item_id: segmentProduct.product_id,
      price: segmentProduct.price,
      quantity: segmentProduct.quantity,
      item_name: segmentProduct.name,
      item_variant_id: segmentProduct.variant,
    });
  }

  return axonItems;
}

export function maybeAddAxonSetupFeeItem(cartPricing: CartPricing, axonItems: AxonItem[]): AxonItem[] {
  if (cartPricing.activationFeesInCents && cartPricing.activationFeesInCents > 0) {
    axonItems.push({
      item_id: 'setup_fee',
      item_name: 'Setup Fee',
      price: centsToDollarsNumber(cartPricing.activationFeesInCents),
      quantity: 1,
    });
  }

  return axonItems;
}
