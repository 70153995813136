import React from 'react';
import styles from './DynamicSizingGuide.module.scss';
import type { SizingGuideItem } from './Dataset';
import { SizeOption } from '../../../../types';

interface DynamicSizingGuideResultProps {
  sizingGuideItem: SizingGuideItem;
  currentWeight: number;
  onSelect: (newValue: SizeOption) => void;
}

const getSizeString = (size: string) => {
  if (size === 'XL') {
    return 'X-Large';
  } else if (size === 'L') {
    return 'Large';
  } else if (size === 'M') {
    return 'Medium';
  } else if (size === 'S') {
    return 'Small';
  } else if (size === 'XS') {
    return 'X-Small';
  } else {
    return 'Unknown';
  }
};

const getSizeOption = (size: string): SizeOption | null => {
  if (size === 'XL') {
    return SizeOption.XLarge;
  } else if (size === 'L') {
    return SizeOption.Large;
  } else if (size === 'M') {
    return SizeOption.Medium;
  } else if (size === 'S') {
    return SizeOption.Small;
  } else if (size === 'XS') {
    return SizeOption.XSmall;
  } else {
    return null;
  }
};

type SizeKey = 'XS' | 'S' | 'M' | 'L' | 'XL';

export default function DynamicSizingGuideResult({
  sizingGuideItem,
  currentWeight,
  onSelect,
}: DynamicSizingGuideResultProps) {
  const sizes: SizeKey[] = ['XS', 'S', 'M', 'L', 'XL'];
  const sortedSizes = sizes
    .map((size) => ({ size, percentage: sizingGuideItem[size] }))
    .sort((a, b) => {
      const percentageA = Number(a.percentage) || 0;
      const percentageB = Number(b.percentage) || 0;
      return percentageB - percentageA;
    });
  const [largest, secondLargest] = sortedSizes;
  const otherSizesPercentage = 100 - Math.round(largest.percentage) - Math.round(secondLargest.percentage);
  const sizeOption = getSizeOption(largest.size);

  return (
    <>
      <div className={styles.sizingGuideResultContainer}>
        {sizingGuideItem?.name && !sizingGuideItem?.isPuppy && (
          <div className={styles.resultHeader}>
            {Math.round(largest.percentage)}% of similar dogs wear size
            <span className={styles.resultHeaderSize}>{' ' + getSizeString(largest.size)}</span>.
          </div>
        )}
        {!sizingGuideItem.name && !sizingGuideItem?.isPuppy && (
          <div className={styles.resultHeader}>
            {Math.round(largest.percentage)}% of similar sized dogs wear sizes
            <span className={styles.resultHeaderSize}>{' ' + getSizeString(largest.size)}</span>.
          </div>
        )}
        {sizingGuideItem.name && !sizingGuideItem?.isPuppy && (
          <div className={styles.currentBreedAndWeightText}>
            Collar sizes of
            <span className={styles.currentBreedAndWeightBoldText}>
              {' ' + currentWeight} lb. {sizingGuideItem.name + ' '}
            </span>
            on Fi:
          </div>
        )}
        {!sizingGuideItem.name && !sizingGuideItem?.isPuppy && (
          <div className={styles.currentBreedAndWeightText}>
            Collar sizes of
            <span className={styles.currentBreedAndWeightBoldText}>
              {' ' + currentWeight} lb. {'dogs '}
            </span>
            on Fi:
          </div>
        )}
        {!sizingGuideItem?.isPuppy && (
          <div className={styles.percentageResultContainer}>
            <div className={styles.percentageResultItem}>
              <div>{getSizeString(largest.size)}</div>
              <div>{Math.round(largest.percentage)}%</div>
            </div>
            <div className={styles.percentageResultItem}>
              <div>{getSizeString(secondLargest.size)}</div>
              <div>{Math.round(secondLargest.percentage)}%</div>
            </div>
            <div className={styles.percentageResultItem}>
              <div>Other</div>
              <div>{Math.round(otherSizesPercentage)}%</div>
            </div>
          </div>
        )}
        {sizingGuideItem?.isPuppy && (
          <div className={styles.puppyRecommendationText}>
            We recommend <span className={styles.puppyRecommendationSizeText}>{' ' + getSizeString(largest.size)}</span>
            .
          </div>
        )}
        <button className={styles.selectSizeButton} onClick={() => sizeOption && onSelect(sizeOption)}>
          {'Select ' + getSizeString(largest.size)}
        </button>
        {!sizingGuideItem?.isPuppy && (
          <div className={styles.stillNotSureText}>
            <span className={styles.stillNotSureBoldText}>Still not sure?</span> We'll replace your collar band for free
            if it's not right.
          </div>
        )}
      </div>
      {sizingGuideItem?.isPuppy && (
        <div className={styles.puppySpecialOfferSection}>
          <div className={styles.puppySpecialOfferTitle}>
            <div className={styles.pawIcon} />
            Fi Puppy Warranty
          </div>
          <div className={styles.puppySpecialOfferDescription}>
            Fi Series 3 is the smallest, lightest wearable for your pup. Get a{' '}
            <span className={styles.puppySpecialOfferBold}>
              {' ' + getSizeString(largest.size).toLowerCase() + ' '}
            </span>
            collar now and we'll swap it out for <span className={styles.puppySpecialOfferBold}>FREE</span> when they
            are fully grown.
          </div>
        </div>
      )}
    </>
  );
}
