import React, { useEffect, useMemo, useState } from 'react';
import { expectUnreachable } from '../../../../lib/util';
import { BandSizeDetails, f1BandSizeOptions, f3BandSizeOptions, sizeDetailsForSizeOption } from '../../../../lib/size';
import { BandSeries, SizeOption } from '../../../../types';
import SizingBlock from './SizingBlock';
import styles from './DynamicSizingGuide.module.scss';
import type { SizingGuideItem } from './Dataset';
import DynamicSizingGuideResult from './DynamicSizingGuideResult';
import classNames from 'classnames';
import * as events from '../../../../lib/analytics/events';
import Loading from '../../../../components/Loading';

interface SizingGuideProps {
  bandSeries: BandSeries;
  onSelect: (newValue: SizeOption) => void;
  close?: () => void;
}

interface SizingBlockProps {
  sizeOption: SizeOption;
  bandSizeDetails: BandSizeDetails;
  onSelect: (newValue: SizeOption) => void;
}

function SizingBlockForSizeOption({ sizeOption, bandSizeDetails, onSelect }: SizingBlockProps) {
  return (
    <SizingBlock
      key={sizeOption}
      dogList={bandSizeDetails.dogList}
      sizeName={bandSizeDetails.sizeName}
      neckSize={bandSizeDetails.neckSize}
      bandWidth={bandSizeDetails.bandWidth}
      onClick={() => onSelect(sizeOption)}
    />
  );
}

export async function getSizingGuideRecommendation(
  weight: number,
  breed: string,
  isPuppy: boolean,
): Promise<SizingGuideItem> {
  let sizingGuideResult: SizingGuideItem | undefined = undefined;
  // lazy import for code splitting
  const { AdultSizingGuide, GenericAdultsSizingGuide, GenericPuppySizingGuide, PuppySizingGuide } = await import(
    './Dataset'
  );
  const breedDataset = (isPuppy ? PuppySizingGuide : AdultSizingGuide).filter((row) => row.name === breed);
  const ceiledWeight = Math.ceil(weight / 10) * 10;
  sizingGuideResult = breedDataset.find((row) => row.weight === ceiledWeight);

  if (!sizingGuideResult) {
    const fallbackDataset: { [key: number]: any } = isPuppy ? GenericPuppySizingGuide : GenericAdultsSizingGuide;
    sizingGuideResult = fallbackDataset[ceiledWeight];
  }
  return sizingGuideResult || { total: 0, XS: 0, S: 0, M: 100, L: 0, XL: 0 };
}

export default function DynamicSizingGuide({ bandSeries, onSelect, close }: SizingGuideProps) {
  const allSizeOptions = useMemo(() => {
    if (bandSeries === BandSeries.F1) {
      return f1BandSizeOptions.map((sizeOption) => ({
        sizeOption,
        bandSizeDetails: sizeDetailsForSizeOption({ bandSeries, sizeOption }),
      }));
    } else if (bandSeries === BandSeries.F3) {
      return f3BandSizeOptions.map((sizeOption) => ({
        sizeOption,
        bandSizeDetails: sizeDetailsForSizeOption({ bandSeries, sizeOption }),
      }));
    } else {
      expectUnreachable(bandSeries);
      throw new Error(`Unsupported band series: ${bandSeries}`);
    }
  }, [bandSeries]);

  const sizeOptionList = useMemo(() => {
    return allSizeOptions.map(
      ({ bandSizeDetails, sizeOption }) =>
        bandSizeDetails && (
          <SizingBlockForSizeOption
            key={sizeOption}
            sizeOption={sizeOption}
            bandSizeDetails={bandSizeDetails}
            onSelect={onSelect}
          />
        ),
    );
  }, [allSizeOptions, onSelect]);

  const [currentBreed, setCurrentBreed] = useState<string | undefined>(undefined);
  const [showBreedSelector, setShowBreedSelector] = useState<boolean>(false);
  const [currentBreedSearch, setCurrentBreedSearch] = useState<string | undefined>(undefined);
  const [currentWeight, setCurrentWeight] = useState<number>(30);
  const [userHasModifiedWeight, setUserHasModifiedWeight] = useState<boolean>(false);

  const [dataset, setDataset] = useState<typeof import('./Dataset') | null>(null);

  useEffect(() => {
    (async () => {
      const {
        SizingGuideDetermination,
        AdultSizingGuide,
        PuppySizingGuide,
        GenericAdultsSizingGuide,
        GenericPuppySizingGuide,
      } = await import('./Dataset');
      setDataset({
        SizingGuideDetermination,
        AdultSizingGuide,
        GenericAdultsSizingGuide,
        GenericPuppySizingGuide,
        PuppySizingGuide,
      });
    })();
  }, []);

  if (!dataset) {
    return <Loading />;
  }

  const updateBreed = (breed: string) => {
    setCurrentBreed(breed);
    events.series3CollarSizingGuideBreedSelected(breed);
    if (!userHasModifiedWeight) {
      setCurrentWeight(Math.round(dataset.SizingGuideDetermination[breed].average_weight));
    }
  };
  const updateWeight = (newWeight: number) => {
    events.series3CollarSizingGuideWeightUpdate(currentWeight, newWeight);
    setCurrentWeight(newWeight);
    setUserHasModifiedWeight(true);
  };
  const showSizingErrorMessage = currentWeight > 200 || currentWeight < 5;

  let sizingGuideResult: SizingGuideItem | undefined = undefined;
  if (currentBreed && currentWeight && currentWeight >= 5 && currentWeight <= 200) {
    const weight = currentWeight;
    const averageWeight = dataset.SizingGuideDetermination[currentBreed]['average_weight'];
    const stdDev = dataset.SizingGuideDetermination[currentBreed]['weight_stddev'];

    const usePuppy = weight <= averageWeight - stdDev;
    const breedDataset = (usePuppy ? dataset.PuppySizingGuide : dataset.AdultSizingGuide).filter(
      (row) => row.name === currentBreed,
    );
    const ceiledWeight = Math.ceil(weight / 10) * 10;
    sizingGuideResult = breedDataset.find((row) => row.weight === ceiledWeight);

    if (!sizingGuideResult) {
      const fallbackDataset: { [key: number]: any } = usePuppy
        ? dataset.GenericPuppySizingGuide
        : dataset.GenericAdultsSizingGuide;
      sizingGuideResult = fallbackDataset[ceiledWeight];
    }

    if (sizingGuideResult) {
      sizingGuideResult.isPuppy = usePuppy;
    }
  }

  return (
    <>
      <div className={classNames(styles.dynamicSizingMain, showBreedSelector ? styles.dynamicSizingNoScroll : null)}>
        <div className={styles.dynamicSizingSection}>
          <div className={styles.dynamicSizingDetailsSection}>
            <div className={styles.header}>Collar Sizing</div>
            <div className={styles.subHeader}>Need help? Tell us more about your dog:</div>
            <button
              className={styles.breedButton}
              onClick={() => {
                setShowBreedSelector(true);
                events.series3CollarSizingGuideBreedSelectorShown();
                setCurrentBreedSearch(undefined);
              }}
            >
              {!currentBreed && <div className={styles.breedPlaceholder}>Breed</div>}
              {currentBreed && <div className={styles.currentBreed}>{currentBreed}</div>}
              <div className={styles.buttonArrow} />
            </button>
            <button
              className={classNames(styles.sizingButton, showSizingErrorMessage ? styles.sizingButtonError : null)}
            >
              <button
                className={styles.minusButton}
                onClick={() => {
                  if (currentWeight - 1 < 5) return;
                  updateWeight(currentWeight - 1);
                }}
              >
                <div className={styles.minusButtonIcon} />
              </button>
              <div className={styles.weight}>
                <input
                  type="number"
                  inputMode="numeric"
                  pattern="[0-9]*"
                  value={currentWeight}
                  min="5"
                  max="200"
                  step="1"
                  onChange={(e) => updateWeight(parseInt(e.currentTarget.value))}
                ></input>
                &nbsp;<div className={styles.poundsText}>{'lbs.'}</div>
              </div>
              <button
                className={styles.plusButton}
                onClick={() => {
                  if (currentWeight + 1 > 200) return;
                  updateWeight(currentWeight + 1);
                }}
              >
                <div className={styles.plusButtonIcon} />
              </button>
            </button>
            {showSizingErrorMessage && (
              <div className={styles.sizingErrorMessage}>Weight must be between 5-200 lbs.</div>
            )}
          </div>
          <div className={styles.dynamicSizingResultSection}>
            {sizingGuideResult && (
              <DynamicSizingGuideResult
                sizingGuideItem={sizingGuideResult}
                currentWeight={currentWeight}
                onSelect={(sizeOption: SizeOption) => {
                  events.series3CollarSizingGuideRecommendationSelected(currentBreed, currentWeight, sizeOption);
                  onSelect(sizeOption);
                  close && close();
                }}
              />
            )}
          </div>
        </div>
        <div className={styles.sizeOptionSection}>
          <div className={styles.header}>General sizing suggestions</div>
          <div className={styles.sizeOptionList}>
            {sizeOptionList}
            <div className={styles.stillUnsure}>
              <h3>Still unsure?</h3>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://support.tryfi.com/hc/en-us/articles/360018860034-Sizing-and-Fit"
              >
                Check out this article
              </a>
              <p>
                *Recommendations based on average breed sizes. All dogs may vary. Measure your dog's existing collar
                length for the most accurate size estimate.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.breedSelectorContainer}>
        <div className={showBreedSelector ? styles.breedSelector : styles.breedSelectorHidden}>
          <button className={styles.backButton} onClick={() => setShowBreedSelector(false)}>
            <div className={styles.backButtonIcon} />
          </button>
          <div className={styles.header}>
            <div className={styles.headerText}>Select Breed</div>
          </div>
          <input
            className={styles.searchBarInput}
            value={currentBreedSearch || ''}
            onChange={(e) => setCurrentBreedSearch(e.currentTarget.value)}
            placeholder="Search"
          />
          <ul>
            {Object.keys(dataset.SizingGuideDetermination)
              .sort((a, b) => {
                if (a === 'Other') return 1;
                if (b === 'Other') return -1;
                return a.localeCompare(b);
              })
              .filter((breed) =>
                currentBreedSearch ? breed.toLowerCase().includes(currentBreedSearch.toLowerCase()) : true,
              )
              .map((breed) => (
                <li className={styles.breedListItem} key={breed}>
                  <button
                    className={styles.breedListItemButton}
                    key={breed}
                    onClick={() => {
                      updateBreed(breed);
                      setShowBreedSelector(false);
                    }}
                  >
                    {breed}
                  </button>
                </li>
              ))}
          </ul>
        </div>
      </div>
    </>
  );
}
