import { InMemoryCache } from '@apollo/client/cache';
import fragmentTypes from '../fragmentTypes';

const possibleTypes = fragmentTypes.__schema.types.reduce((acc, type) => {
  if (type.possibleTypes) {
    acc[type.name] = type.possibleTypes.map((possibleType) => possibleType.name);
  }
  return acc;
}, {} as { [key: string]: string[] });

const cache = new InMemoryCache({
  possibleTypes,
  typePolicies: {
    User: {
      fields: {
        billingAccount: {
          merge(existing, incoming, { mergeObjects }) {
            return mergeObjects(existing, incoming);
          },
        },
      },
    },
  },
});

export default cache;
